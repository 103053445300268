import React from 'react';
import {connect} from 'react-redux';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import {changeSiteDefaultAisleType} from '../../actions/siteLevelActions';
import {isAisleTypeValid} from '../../validations/validations';

class AisleTypePicker extends React.Component {
    generateOptions() {
        const options = [<option value='' key="not_selected">Not Selected</option>];
        if (this.props.available) {
            const availableKeys = Object.keys(this.props.available);
            for (const key of availableKeys) {
                const {s3Key, configName} = this.props.available[key];
                options.push(<option value={s3Key} key={s3Key}>{configName}</option>);
            }
            if (this.props.selected && !availableKeys.includes(this.props.selected)) {
                options.push(<option value={this.props.selected} key={this.props.selected}>
                    Invalid: {this.props.selected}
                </option>);
            }
        }
        return options;
    }

    handleChange(event) {
        const s3Key = event.target.value === '' ? null : event.target.value;
        const aisleType = this.props.available[s3Key]
            ? this.props.available[s3Key]
            : null;
        this.props.changeAisleType(aisleType);
    }

    render() {
        return <InputGroup className="site-level-picker">
            <InputGroup.Prepend>
                <InputGroup.Text id="aisle-type-picker-label">Default
                    {this.props.aisleGenerationSetting.shouldEnterBinLabel ? ' Rack' : ' Aisle'} Type &nbsp;
                    <span className="required-field">*</span>
                </InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl as="select"
                required
                aria-describedby="aisle-type-picker-label"
                className="site-level-picker custom-select shadow-none"
                value={this.props.selected || ''}
                onChange={this.handleChange.bind(this)}
                isInvalid={this.props.validated && !isAisleTypeValid()}>
                {this.generateOptions()}
            </FormControl>
            <FormControl.Feedback type="invalid">Aisle type is required</FormControl.Feedback>
        </InputGroup>;
    }
}

function mapStateToProps(state) {
    return {
        available: state.resource.filteredAisleTypes,
        selected: state.siteConfig.aisleType ? state.siteConfig.aisleType.s3Key : undefined,
        validated: state.validated,
        aisleGenerationSetting: state.aisleGenerationSetting
    };
}

function mapDispatchToProps(dispatch) {
    return {
        changeAisleType: (aisleType) => dispatch(changeSiteDefaultAisleType(aisleType))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(AisleTypePicker);
