import {applyMiddleware, createStore, compose} from 'redux';
import createRootReducer from './reducers/reducers';
import thunk from 'redux-thunk';
import {cachingSubscriber, getPreviousSession} from './caching';
import {createBrowserHistory} from 'history';
import {routerMiddleware} from 'connected-react-router';

export const history = createBrowserHistory();
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
    createRootReducer(history),
    getPreviousSession(),
    composeEnhancers(applyMiddleware(thunk, routerMiddleware(history))));

store.subscribe(cachingSubscriber);
export default store;
