import AbstractAisleGenerator from './AbstractAisleGenerator';
import {AISLE_CONNECTION_SIDE_TYPES} from '../../components/Aisle/AisleStateManipulations';

class StackingFilterBinAisleGenerator extends AbstractAisleGenerator {
    static getName() {
        return 'StackingFilterBinAisleGenerator';
    }

    generateRackEntities(aisle, aisleTemplate, offsetIndex, workspaceToWalkWayMapping) {
        const processIndex = aisle.connectionSide === AISLE_CONNECTION_SIDE_TYPES.LEFT
            ? offsetIndex : aisle.racks.length - offsetIndex - 1;
        const entitiesInCurrentRack = [];
        const rack = aisle.racks[processIndex];
        const offset = this.getPixelOffset(aisleTemplate.attributes, offsetIndex);
        const templateLabelMapping = this.getLabelMappingForTemplate(aisleTemplate, rack, aisle.connectionSide);
        for (const entity of aisleTemplate.entities) {
            if (!entity.label || templateLabelMapping[entity.label] === '-') {
                continue;
            }
            entitiesInCurrentRack.push({
                label: templateLabelMapping[entity.label],
                attributes: {
                    walkway: workspaceToWalkWayMapping[aisle.workspace.value]
                },
                segments: this.addOffsetToSegments(entity.segments, offset)
            });
        }
        return entitiesInCurrentRack;
    }

    getPixelOffset(aisleTemplateAttributes, index) {
        return index * aisleTemplateAttributes.totalPixel;
    }

    generateAisle(macAddress, aisle, aisleTemplate, workspaceToWalkWayMapping) {
        const {name: aisleName, port} = aisle;
        const entities = [];

        for (let i = 0; i < aisle.racks.length; i++) {
            entities.push(...this.generateRackEntities(aisle, aisleTemplate, i, workspaceToWalkWayMapping));
        }

        return {
            label: aisleName,
            macAddress,
            port: parseInt(port, 10),
            entities
        };
    }

    getLabelMappingForTemplate(aisleTemplate, rack, direction) {
        const mapping = {};
        const rows = aisleTemplate.attributes.rows;
        const cols = aisleTemplate.attributes.cols;
        for (let i = 0; i < rows; i++) {
            for (let j = 0; j < cols; j++) {
                mapping['Bin-' + i + '-' + j] = direction === AISLE_CONNECTION_SIDE_TYPES.LEFT ? rack[i][j] : rack[i][cols - j - 1];
            }
        }
        return mapping;
    }

    addOffsetToSegments(segments, offset) {
        return segments.map((segment) => {
            const x = {
                startIndex: segment.startIndex + offset,
                endIndex: segment.endIndex + offset,
                classNames: segment.classNames
            };
            return x;
        });
    }
}

export default StackingFilterBinAisleGenerator;
