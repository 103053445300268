import React from 'react';
import {connect} from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import {toggleAddAislesModal, addMultipleAisles} from '../../actions/actions';
import { ButtonGroup } from 'react-bootstrap';
import {getAisleNameAttributes} from '../../configs/app-config';

class AddMultipleAisleModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = AddMultipleAisleModal.getInitialState();
    }

    static getStateKeys() {
        return {
            START_AISLE: 'startAisle',
            START_PORT: 'startPort',
            COUNT: 'count',
            START_COLUMN: 'startColumn',
            END_COLUMN: 'endColumn',
            STEPPING: 'stepping',
            aisleType: 'aisleType'
        };
    }

    static getInitialState() {
        return {
            startAisle: '', // NonNull
            startPort: null,
            count: null,
            startColumn: '',
            endColumn: '',
            stepping: 1,
            aisleType: null,
            validated: false
        };
        /*
        const stateKeyMap = AddMultipleAisleModal.getStateKeys();
        const initialState = {
            validated: false
        };
        for (const k of Object.values(stateKeyMap)) {
            initialState[k] = null;
        }
        initialState.stepping = 1;
        return initialState;
        */
    }

    isStateValid() {
        const {startAisle, startPort, count} = this.state;
        if (!new RegExp(getAisleNameAttributes(this.props.aisleGenerationSetting).aisleNamePattern).test(startAisle)) {
            return false;
        }
        if (startPort == null || startPort < 1 || startPort > this.props.maxPortsAllowed) {
            return false;
        }
        if (count == null || count < 1 || count > this.props.maxPortsAllowed) {
            return false;
        }
        return true;
    }

    getStateAsString(stateKey) {
        return this.state[stateKey] == null ? '' : this.state[stateKey];
    }

    generateAisleTypeOptions() {
        const options = [<option value={undefined} key="default">Default</option>];
        if (this.props.availableAisleTypes) {
            for (const key of Object.keys(this.props.availableAisleTypes)) {
                const {s3Key, configName} = this.props.availableAisleTypes[key];
                options.push(<option value={s3Key} key={s3Key}>{configName}</option>);
            }
        }
        return options;
    }

    handleConfirm() {
        this.setState({validated: true});
        if (this.isStateValid()) {
            this.props.save(this.props.controllerId, this.state);
            this.handleClose();
        }
    }

    handleClose() {
        this.setState(AddMultipleAisleModal.getInitialState());
        this.props.close();
    }

    handleStartAisleChange(event) {
        const startAisle = event.target.value.toUpperCase();
        this.setState({startAisle});
    }

    handleStartPortChange(event) {
        const startPort = parseInt(event.target.value, 10);
        if (!isNaN(startPort)) {
            this.setState({startPort});
        } else {
            this.setState({startPort: null});
        }
    }

    handleCountChange(event) {
        const count = parseInt(event.target.value, 10);
        if (!isNaN(count)) {
            this.setState({count});
        } else {
            this.setState({count: null});
        }
    }

    handleStartColumnChange(event) {
        const startColumn = event.target.value.toUpperCase();
        this.setState({startColumn});
    }

    handleEndColumnChange(event) {
        const endColumn = event.target.value.toUpperCase();
        this.setState({endColumn});
    }

    handleAisleTypeChange(event) {
        const s3Key = event.target.value;
        const aisleType = this.props.availableAisleTypes[s3Key]
            ? this.props.availableAisleTypes[s3Key]
            : null;
        this.setState({aisleType});
    }

    setStepping(stepping) {
        this.setState({stepping});
    }

    render() {
        const {aisleNamePlaceholder, aisleNamePattern} = getAisleNameAttributes(this.props.aisleGenerationSetting);
        const {aisleGenerationSetting} = this.props;
        const allowAisleTypeOverride = aisleGenerationSetting.allowAisleTypeOverride;
        const hideColumnInputs = aisleGenerationSetting.hideColumnInputs;

        return <Modal show={this.props.show} onHide={this.handleClose.bind(this)}>
            <Form noValidate
                validated={this.state.validated}
                onSubmit={(event) => {
                    event.preventDefault();
                    this.handleConfirm();
                }}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{this.props.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className="mt-2 mb-2">
                        <Col>
                            <InputGroup className="mt-2">
                                <InputGroup.Prepend>
                                    <InputGroup.Text>Start Aisle &nbsp; <span className="required-field">*</span> </InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl required
                                    placeholder={aisleNamePlaceholder}
                                    pattern={aisleNamePattern}
                                    value={this.state.startAisle}
                                    onChange={this.handleStartAisleChange.bind(this)}/>
                                <Form.Control.Feedback type="invalid">
                                    {`Aisle name must have a valid format. Example: ${aisleNamePlaceholder}`}
                                </Form.Control.Feedback>
                            </InputGroup>
                        </Col>
                        <Col>
                            <InputGroup className="mt-2">
                                <InputGroup.Prepend>
                                    <InputGroup.Text>Start Port &nbsp; <span className="required-field">*</span></InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl required
                                    type="number"
                                    placeholder="1"
                                    min="1"
                                    max={this.props.maxPortsAllowed}
                                    value={this.getStateAsString(AddMultipleAisleModal.getStateKeys().START_PORT)}
                                    onChange={this.handleStartPortChange.bind(this)}/>
                                <Form.Control.Feedback type="invalid">
                                    {`Start port must be between 1 and ${this.props.maxPortsAllowed}`}
                                </Form.Control.Feedback>
                            </InputGroup>
                        </Col>
                    </Row>
                    <Row className="mt-2 mb-2">
                        <Col>
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text>Aisle Count &nbsp; <span className="required-field">*</span></InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl required
                                    type="number"
                                    min="1"
                                    max={this.props.maxPortsAllowed}
                                    placeholder="2"
                                    value={this.getStateAsString(AddMultipleAisleModal.getStateKeys().COUNT)}
                                    onChange={this.handleCountChange.bind(this)}/>
                                <Form.Control.Feedback type="invalid">
                                    {`Aisle count must be between 1 and ${this.props.maxPortsAllowed}`}
                                </Form.Control.Feedback>
                            </InputGroup>
                        </Col>
                        {
                            !hideColumnInputs
                                ? <>
                                    <Col>
                                        <InputGroup>
                                            <InputGroup.Prepend>
                                                <InputGroup.Text>Stepping &nbsp; <span className="required-field">*</span></InputGroup.Text>
                                            </InputGroup.Prepend>
                                            <ButtonGroup>
                                                <Button variant={this.state.stepping === 1 ? 'primary' : 'outline-primary'} onClick={() => this.setStepping(1)}>1</Button>
                                                <Button variant={this.state.stepping === 2 ? 'primary' : 'outline-primary'} onClick={() => this.setStepping(2)}>2</Button>
                                            </ButtonGroup>
                                        </InputGroup>
                                    </Col>
                                </>
                                : undefined
                        }
                    </Row>
                    {
                        !hideColumnInputs
                            ? <Row className="mt-2 mb-2">
                                <Col>
                                    <InputGroup>
                                        <InputGroup.Prepend>
                                            <InputGroup.Text>Start Column &nbsp; <span className="required-field">*</span></InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <FormControl placeholder="A"
                                            value={this.getStateAsString(AddMultipleAisleModal.getStateKeys().START_COLUMN)}
                                            onChange={this.handleStartColumnChange.bind(this)}/>
                                    </InputGroup>
                                </Col>
                                <Col>
                                    <InputGroup>
                                        <InputGroup.Prepend>
                                            <InputGroup.Text>End Column &nbsp; <span className="required-field">*</span></InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <FormControl placeholder="G"
                                            value={this.getStateAsString(AddMultipleAisleModal.getStateKeys().END_COLUMN)}
                                            onChange={this.handleEndColumnChange.bind(this)}/>
                                    </InputGroup>
                                </Col>
                            </Row>
                            : undefined
                    }
                    {
                        allowAisleTypeOverride
                            ? <Row>
                                <Col>
                                    <InputGroup>
                                        <InputGroup.Prepend>
                                            <InputGroup.Text>Aisle Type &nbsp;</InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <FormControl as="select"
                                            size="sm"
                                            className="custom-select shadow-none"
                                            value={this.state.aisleType ? this.state.aisleType.s3Key : undefined}
                                            onChange={this.handleAisleTypeChange.bind(this)}>
                                            {this.generateAisleTypeOptions()}
                                        </FormControl>
                                    </InputGroup>
                                </Col>
                            </Row>
                            : undefined
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={this.handleClose.bind(this)}>Cancel</Button>
                    <Button variant="success" type="submit">Confirm</Button>
                </Modal.Footer>
            </Form>
        </Modal>;
    }
}

function mapStateToProps(state) {
    const controllerId = state.expandedController;
    const controller = controllerId == null ? null : state.siteConfig.controllers[controllerId];
    const controllerMac = controller == null || controller.macAddress == null ? '<not defined>' : controller.macAddress;
    const title = controller == null ? 'Invalid controller specified' : `Add to LED controller ${controllerId + 1} (MAC ${controllerMac})`;
    return {
        maxPortsAllowed: state.maxPortsAllowed,
        show: state.modalStatus.addMultipleAisles.show,
        controllerId,
        controller,
        title,
        availableAisleTypes: state.resource.filteredAisleTypes,
        aisleGenerationSetting: state.aisleGenerationSetting
    };
}

function mapDispatchToProps(dispatch) {
    return {
        close: () => dispatch(toggleAddAislesModal(false)),
        save: (controllerId, payload) => {
            dispatch(addMultipleAisles(controllerId, payload));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddMultipleAisleModal);
