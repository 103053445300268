import {AISLE_MISSING_COL_PATTERN, MAC_ADDRESS_PATTERN, STATION_NAME_PATTERN} from '../constants';
import {AisleRow} from '../components/Aisle/AisleRow';
import {getAisleNameAttributes} from '../configs/app-config';
import store from '../store';

function areValidAisles(aisles, maxPortsAllowed, aisleGenerationSetting) {
    for (let i = 0; i < aisles.length; i++) {
        const aisle = aisles[i];
        const invalidResponse = {valid: false, firstInvalid: i};
        if (!isValidAisleName(aisle.name, aisleGenerationSetting) || aisle.port < 0 || aisle.port > maxPortsAllowed) {
            return invalidResponse;
        }
        if (!aisleGenerationSetting.hideColumnInputs) {
            if (!isValidMissingColumns(aisle.missingColumns) || !AisleRow.isValidColumnInput(aisle.startColumn)
                || !AisleRow.isValidColumnInput(aisle.endColumn)) {
                return invalidResponse;
            }
        }
        if (aisleGenerationSetting.shouldEnterBinLabel) {
            if (!isValidRacks(aisle.racks)) {
                return invalidResponse;
            }
            if (!aisle.workspace) {
                return invalidResponse;
            }
            if (!aisle.connectionSide) {
                return invalidResponse;
            }
        }
    }
    return {valid: true};
}

function isValidRacks(racks) {
    for (const rack of racks) {
        const rackValid = rack.every((row) => row.every((bin) => bin && bin.length !== 0));
        if (!rackValid) {
            return false;
        }
    }
    return true;
}

export function isValidMacAddress(macAddress) {
    return macAddress.trim().length > 0 && new RegExp(MAC_ADDRESS_PATTERN).test(macAddress);
}

export function isValidAisleName(aisleName, aisleGenerationSetting) {
    if (typeof aisleName !== 'string') {
        return false;
    }
    const trimmedName = aisleName.trim();
    const namePattern = getAisleNameAttributes(aisleGenerationSetting).aisleNamePattern;
    return trimmedName.length > 0 && new RegExp(namePattern).test(aisleName);
}

function isValidMissingColumns(missingColumn) {
    const trimmedMissingCols = missingColumn.trim();
    return trimmedMissingCols.length === 0 || (trimmedMissingCols.length > 0 && new RegExp(AISLE_MISSING_COL_PATTERN).test(missingColumn));
}

function areValidControllers() {
    const {siteConfig: siteConfigState, maxPortsAllowed, aisleGenerationSetting} = store.getState();
    const {controllers} = siteConfigState;
    for (let i = 0; i < controllers.length; i++) {
        const controller = controllers[i];
        const aisleValidity = areValidAisles(controller.aisles, maxPortsAllowed, aisleGenerationSetting);
        if (!aisleValidity.valid) {
            return {
                valid: false,
                firstInvalid: i,
                firstInvalidAisle: aisleValidity.firstInvalid
            };
        }
        if (!isValidMacAddress(controller.macAddress)) {
            return {
                valid: false,
                firstInvalid: i
            };
        }
    }
    return {
        valid: true
    };
}

function areSiteLevelInputsValid() {
    const state = store.getState();
    return {
        valid: isValidStationName(state.siteConfig.stationName)
            && isLedControllerTypeValid()
            && isAisleTypeValid()
    };
}

export function isValidStationName(stationName) {
    const trimmedName = stationName == null ? '' : stationName.trim();
    return trimmedName.length >= 4 && new RegExp(STATION_NAME_PATTERN).test(stationName);
}

export function isLedControllerTypeValid() {
    const state = store.getState();
    const selected = state.siteConfig.ledControllerType ? state.siteConfig.ledControllerType.s3Key : undefined;
    const available = state.resource.ledControllerTypes;
    if (selected == null) {
        return false;
    }
    if (available && !Object.keys(available).includes(selected)) {
        return false;
    }
    return true;
}

export function isAisleTypeValid() {
    const state = store.getState();
    const selected = state.siteConfig.aisleType ? state.siteConfig.aisleType.s3Key : undefined;
    const available = state.resource.filteredAisleTypes;
    if (selected == null) {
        return false;
    }
    if (available && !Object.keys(available).includes(selected)) {
        return false;
    }
    return true;
}

export default function validateState() {
    const controllersValidity = areValidControllers();
    if (!controllersValidity.valid) {
        return controllersValidity;
    }
    return areSiteLevelInputsValid();
}
