import {COLUMN_ALPHABETS} from '../constants';

function findMissingColumns(columns) {
    const missingColumns = [];
    if (columns && columns.length > 0) {
        const startColumnIndex = COLUMN_ALPHABETS.indexOf(columns[0]);
        const endColumnIndex = COLUMN_ALPHABETS.indexOf(columns[columns.length - 1]);
        for (let i = Math.min(startColumnIndex, endColumnIndex); i <= Math.max(startColumnIndex, endColumnIndex); i += 1) {
            if (!columns.includes(COLUMN_ALPHABETS[i])) {
                missingColumns.push(COLUMN_ALPHABETS[i]);
            }
        }
    }
    return missingColumns;
}

function convertSiteLayoutToControllers(siteLayout) {
    // Group aisles by mac address
    const controllersByMac = new Map();
    const controllers = [];

    for (const aisleName in siteLayout) {
        const {macAddress, port, colOrder, aisleConfig} = siteLayout[aisleName];
        if (!controllersByMac.has(macAddress)) {
            controllersByMac.set(macAddress, {macAddress, aisles: []});
        }
        controllersByMac.get(macAddress).aisles.push({
            name: aisleName,
            port,
            startColumn: colOrder[0],
            endColumn: colOrder[colOrder.length - 1],
            missingColumns: findMissingColumns(colOrder).join(','),
            aisleConfig
        });
    }

    for (const macAddress of controllersByMac.keys()) {
        controllers.push(controllersByMac.get(macAddress));
    }
    return controllers;
}

export default (siteConfig) => {
    const siteConfigKeys = Object.keys(siteConfig);
    if (siteConfigKeys.includes('controllers')) {
        // siteConfig is actually site planning parameters
        // - a dump of the app's internal redux state
        return siteConfig;
    } else if (siteConfigKeys.includes('generationParameters')) {
        // New siteConfig (v2) with site planning parameters embedded
        return siteConfig.generationParameters;
    } else if (siteConfigKeys.includes('siteLayout')) {
        // Old siteConfig (v1) with a siteLayout and LED controller config referenced
        return {
            aisleType: siteConfig.aisleConfig || null,
            ledControllerType: siteConfig.ledControllerConfig || null,
            colorOrder: siteConfig.colorOrder,
            stationName: siteConfig.siteId,
            controllers: convertSiteLayoutToControllers(siteConfig.siteLayout)
        };
    }

    // Defaults to the very old siteConfig (legacy) format which is just a map of aisle names ("A1") to aisle parameters
    // (macAddress, port, colOrder)
    return {
        aisleType: null,
        ledControllerType: null,
        colorOrder: 'RGB',
        stationName: null,
        controllers: convertSiteLayoutToControllers(siteConfig)
    };
};
