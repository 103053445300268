import { combineReducers } from 'redux';
import ledControllerReducer from './siteConfig/ledControllerReducer';
import stationNameReducer from './siteConfig/stationNameReducer';
import colorOrderReducer from './siteConfig/colorOrderReducer';
import {IMPORT_SITE_CONFIG_FROM_FILE, RESET_SITE_CONFIG} from '../actions/actionTypes';
import aisleTypeReducer from './siteConfig/aisleTypeReducer';
import ledControllerTypeReducer from './siteConfig/ledControllerTypeReducer';
import workspacesReducer from './siteConfig/workspacesReducer';

const combinedReducer = combineReducers({
    aisleType: aisleTypeReducer,
    ledControllerType: ledControllerTypeReducer,
    colorOrder: colorOrderReducer,
    stationName: stationNameReducer,
    controllers: ledControllerReducer,
    workspaces: workspacesReducer,
});

export default (state = {}, action) => {
    switch (action.type) {
        case IMPORT_SITE_CONFIG_FROM_FILE:
            return action.siteConfig;
        case RESET_SITE_CONFIG:
            return combinedReducer({stationName: state.stationName}, action);
        default:
            return combinedReducer(state, action);
    }
};
