import React from 'react';
import {withAuthenticator, RequireNewPassword, ForgotPassword} from 'aws-amplify-react';
import withReduxStore from './components/HighOrderComponents/with-redux-store';
import SignInSkipVerifyContact from './components/amplify/sign-in-skip-verify-contact';
import './App.css';
import { ConnectedRouter } from 'connected-react-router';
import {history} from './store';
import { Route, Switch, Redirect } from 'react-router';
import SitePlanningPage from './components/pages/SitePlanningPage';
import AppDataStore, {APP_DATA_KEYS} from "./components/app-data";

class App extends React.Component {
    render() {
        AppDataStore.save(APP_DATA_KEYS.TO_BE_DELETE_CONTROLLERS, []);
        return <ConnectedRouter history={history}>
            <div className="whole-view">
                <Switch>
                    <Route path="/site/:siteId?" component={SitePlanningPage}/>
                    <Route path="/" render={() => <Redirect to="/site/"/>}/>
                </Switch>
            </div>
        </ConnectedRouter>;
    }
}

export default withAuthenticator(
    withReduxStore(App),
    true,
    [<SignInSkipVerifyContact/>, <RequireNewPassword/>, <ForgotPassword/>]);
