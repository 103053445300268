import {
    UPDATE_AISLE_TYPES,
    UPDATE_LED_CONTROLLER_TYPES,
    FILTER_AISLE_TYPES,
    FILTER_LED_CONTROLLER_TYPES
} from './actionTypes';
import {getAppConfig} from '../configs/app-config';
import StackingFilterBinAisleGenerator from '../transformations/aisleConfigGenerators/StackingFilterBinAisleGenerator';
import {INSC_LED_CONTROLLER_MODELS} from '../constants';

export function updateAisleTypes(aisleTypes) {
    return async(dispatch) => {
        dispatch({
            type: UPDATE_AISLE_TYPES,
            aisleTypes
        });
        dispatch(filterAisleTypes());
    };
}

function isCompatibleAisleType(configName, generatorName, aisleConfigRegexes) {
    return configName.match(aisleConfigRegexes[generatorName]);
}

export function filterAisleTypes() {
    return (dispatch, getState) => {
        const state = getState();
        const aisleTypes = state.resource.aisleTypes || {};
        const {aisleConfigGeneratorName} = state.aisleGenerationSetting;
        const {enableAisleTypeFiltering, aisleConfigRegexByGeneratorType} = getAppConfig().opConfig;
        let filteredAisleTypes = {};

        if (enableAisleTypeFiltering) {
            for (const [s3Key, config] of Object.entries(aisleTypes)) {
                if (isCompatibleAisleType(config.configName, aisleConfigGeneratorName, aisleConfigRegexByGeneratorType)) {
                    filteredAisleTypes[s3Key] = config;
                }
            }
        } else {
            filteredAisleTypes = aisleTypes;
        }

        dispatch({
            type: FILTER_AISLE_TYPES,
            filteredAisleTypes
        });

    };
}

export function filterLedControllerTypes() {
    return (dispatch, getState) => {
        const state = getState();
        const ledControllerTypes = state.resource.ledControllerTypes || {};
        const {aisleConfigGeneratorName} = state.aisleGenerationSetting;
        let filteredLedControllerTypes = {};
        if (aisleConfigGeneratorName === StackingFilterBinAisleGenerator.getName()) {
            for (const [ledControllerKey, value] of Object.entries(ledControllerTypes)) {
                if (INSC_LED_CONTROLLER_MODELS.has(ledControllerKey)) {
                    filteredLedControllerTypes[ledControllerKey] = value;
                }
            }
        } else {
            filteredLedControllerTypes = ledControllerTypes;
        }
        dispatch({
            type: FILTER_LED_CONTROLLER_TYPES,
            filteredLedControllerTypes
        });
    };
}
export function updateLedControllerTypes(ledControllerTypes) {
    return async(dispatch) => {
        dispatch({
            type: UPDATE_LED_CONTROLLER_TYPES,
            ledControllerTypes
        });
        dispatch(filterLedControllerTypes());
    };
}
