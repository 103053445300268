import React, {Component} from 'react';
import expandIcon from '../Controllers/expand.svg';
import {connect} from 'react-redux';
import {Container} from 'react-bootstrap';

export class ControllerHeader extends Component {
    getAisleSummary() {
        let summary = this.props.aisleString;
        if (this.props.totalAisles > 0) {
            summary += ` (total ${this.props.totalAisles})`;
        }

        return summary;
    }

    render() {
        return <Container className="controller-header">
            <img src={expandIcon} className={`expand ${this.props.collapsed ? 'up' : 'down'}`} alt="Expand Controller"/>
            <span className="hide-in-mobile"> LED </span>
            <span> CONTROLLER {this.props.controllerId + 1}&nbsp;&nbsp;</span>
            <span className="hide-in-mobile">&nbsp;&nbsp;&nbsp;&nbsp;</span>
            <span> {this.getAisleSummary()} &nbsp;&nbsp;</span>
            <span className="hide-in-mobile"> &nbsp;&nbsp;&nbsp;&nbsp; </span>
            <span> {this.props.macAddress}</span>
        </Container>;
    }
}

function mapStateToProps(state, ownProps) {
    const currentController = state.siteConfig.controllers[ownProps.controllerId];
    const totalAisles = currentController.aisles.length;
    const aisleString = !totalAisles ? '' : 'Aisle ' + currentController.aisles[0].name + ' - ' + currentController.aisles[totalAisles - 1].name;
    return {
        collapsed: ownProps.controllerId === state.expandedController,
        macAddress: currentController.macAddress,
        totalAisles: totalAisles,
        aisleString: aisleString
    };
}

function mapDispatchToProps(dispatch, ownProps) {
    return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(ControllerHeader);
