import React from 'react';
import {connect} from 'react-redux';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import {changeLedControllerType} from '../../actions/siteLevelActions';
import {isLedControllerTypeValid} from '../../validations/validations';

class LedControllerTypePicker extends React.Component {
    generateOptions() {
        const options = [<option value='' key="not_selected">Not Selected</option>];
        if (this.props.available) {
            const availableKeys = Object.keys(this.props.available);

            for (const key of availableKeys) {
                const {s3Key, configName} = this.props.available[key];
                options.push(<option value={s3Key} key={s3Key}>{configName}</option>);
            }
            if (this.props.selected && !availableKeys.includes(this.props.selected)) {
                options.push(<option value={this.props.selected} key={this.props.selected}>Invalid: {this.props.selected}</option>);
            }
        }
        return options;
    }

    handleChange(event) {
        const s3Key = event.target.value === '' ? null : event.target.value;
        const ledControllerType = this.props.available[s3Key]
            ? this.props.available[s3Key]
            : null;
        this.props.changerLedControllerType(ledControllerType);
    }

    render() {
        return <InputGroup className="site-level-picker">
            <InputGroup.Prepend>
                <InputGroup.Text>LED Controller Type &nbsp;<span
                    className="required-field">*</span></InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl required
                as="select"
                aria-describedby="racktype-label"
                className="site-level-picker custom-select shadow-none"
                value={this.props.selected || ''}
                onChange={this.handleChange.bind(this)}
                isInvalid={this.props.validated && !isLedControllerTypeValid()}>
                {this.generateOptions()}
            </FormControl>
            <FormControl.Feedback type="invalid">LED controller type is required</FormControl.Feedback>
        </InputGroup>;
    }
}

function mapStateToProps(state) {
    return {
        available: state.resource.filteredLedControllerTypes,
        selected: state.siteConfig.ledControllerType ? state.siteConfig.ledControllerType.s3Key : undefined,
        validated: state.validated
    };
}

function mapDispatchToProps(dispatch) {
    return {
        changerLedControllerType: (ledControllerType) => dispatch(changeLedControllerType(ledControllerType))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(LedControllerTypePicker);
