import RackBasedGenerator from './RackBasedGenerator';
import AisleBasedGenerator from './AisleBasedGenerator';
import StackingFilterBinAisleGenerator from './StackingFilterBinAisleGenerator';

export default {
    RackBasedGenerator: RackBasedGenerator,
    AisleBasedGenerator: AisleBasedGenerator,
    StackingFilterBinAisleGenerator: StackingFilterBinAisleGenerator
};

