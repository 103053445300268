import React from 'react';
import {Accordion} from 'react-bootstrap';
import '../Aisle/Aisle.css';
import {connect} from 'react-redux';
import {AisleHeaderWithToolTip} from '../Aisle/AisleHeaderWithToolTip';
import {AisleCard} from './AisleCard';

class ManualEntryAisleTable extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            ...props,
            activeAisle: 0
        };
        this.getKey = this.getKey.bind(this);
    }
    getKey(aisleIndex) {
        return this.props.controllerId + '-' + aisleIndex;
    }
    render() {
        console.log(this.props);
        return (
            <div>
                <AisleHeaderWithToolTip/>
                <Accordion className='labelled-bin-accordion'
                    defaultActiveKey={this.getKey(0)}>
                    {
                        this.props.aisles.map((aisle, index) => (
                            <AisleCard eventKey={this.getKey(index)}
                                controllerId={this.props.controllerId}
                                key={this.getKey(index)}
                                aisleIndex={index} aisle={this.props.aisles[index]}
                                invalid={this.props.validated
                                       && this.props.firstInvalid.controllerId === this.props.controllerId
                                       && this.props.firstInvalid.aisleIndex === index} />

                        ))
                    }
                </Accordion>
            </div>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        aisles: state.siteConfig.controllers[ownProps.controllerId].aisles,
        firstInvalid: state.firstInvalid,
        validated: state.validated
    };
}

function mapDispatchToProps(dispatch) {
    return {
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ManualEntryAisleTable);
