import React, {Component} from 'react';
import {Form, Table} from 'react-bootstrap';
import './BinTable.css';
import {ReactComponent as RemoveWorkSpaceIcon} from '../Controllers/x.svg';
import Button from 'react-bootstrap/Button';
import {changeRackData, deleteRackFromAisle} from '../../actions/actions';
import {connect} from 'react-redux';

class BinInputTable extends Component {

    createRows() {
        const rowComps = [];
        for (let i = 0; i < this.props.totalRows; i++) {
            const cells = [];
            for (let j = 0; j < this.props.totalCols; ++j) {
                cells.push(
                    <td className="bin-label-input" key={j}>
                        <>
                        <Form.Control required value={this.props.currentRack[i][j]}
                            placeholder='ATS_BAG_ABCD'
                            onChange={(e) => this.updateLocalState(i, j, e)}
                            className="bin-input"/>
                        <Form.Control.Feedback type="invalid">Use ' - ' for bins that has no name</Form.Control.Feedback>
                    </>
                    </td>
                );

            }
            rowComps.push(
                <tr className='bin-input-tr' key={i}>
                    {cells}
                </tr>
            );
        }
        return rowComps;
    }

    updateLocalState(row, col, e) {
        const newRack = this.props.currentRack.map(function(arr) {
            return arr.slice();
        });
        newRack[row][col] = e.target.value;
        this.props.onValueChange(newRack);
    }

    render() {
        return (
            <div className='bin-table-outer-div'>
                <Table className='bin-label-input-table' size="sm" bordered>
                    <tbody>
                        {this.createRows()}
                    </tbody>
                </Table>
                <Button className="remove-rack-btn image-buttons no-border btn-outline-light">
                    <RemoveWorkSpaceIcon onClick={this.props.onDelete}/>
                </Button>
            </div>
        );
    }

}
function mapStateToProps(state, ownProps) {
    const currentRack = state.siteConfig.controllers[ownProps.controllerId].aisles[ownProps.aisleIndex].racks[ownProps.rackIndex];
    return {
        totalRows: currentRack.length,
        totalCols: currentRack[0].length,
        currentRack: currentRack
    };
}

function mapDispatchToProps(dispatch, ownProps) {
    return {
        onDelete: () => dispatch(deleteRackFromAisle(ownProps.controllerId, ownProps.aisleIndex, ownProps.rackIndex)),
        onValueChange: (data) => dispatch(changeRackData(ownProps.controllerId, ownProps.aisleIndex, ownProps.rackIndex, data)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(BinInputTable);
