let config = {};

async function loadAppConfig() {
    const baseUrl = window.location.origin;
    config = await fetch(`${baseUrl}/config/config.json`).then((response) => response.json());
    console.log(`Loaded config=${JSON.stringify(config)}`);
}

function getAppConfig() {
    return config;
}

function getAisleNameAttributes(aisleGenerationSetting) {
    const aisleNamePlaceholder = aisleGenerationSetting.aisleNamePlaceholderOverride
        ? aisleGenerationSetting.aisleNamePlaceholderOverride : config.opConfig.aisleNamePlaceholder;
    const aisleNamePattern = aisleGenerationSetting.aisleNamePatternOverride
        ? aisleGenerationSetting.aisleNamePatternOverride : config.opConfig.aisleNamePattern;
    return {
        aisleNamePlaceholder,
        aisleNamePattern
    };
}

export {loadAppConfig, getAppConfig, getAisleNameAttributes};
