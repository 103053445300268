import RackBasedGenerator from '../transformations/aisleConfigGenerators/RackBasedGenerator';
import AisleBasedGenerator from '../transformations/aisleConfigGenerators/AisleBasedGenerator';
import * as SitePlanningApi from '../apis/site-planning-api';
import StackingFilterBinAisleGenerator from '../transformations/aisleConfigGenerators/StackingFilterBinAisleGenerator';

const RACK_LAYOUT_TYPES = {
    EU_RACK: 'EU_RACK',
    BAKER: 'BAKER',
    MODULAR: 'MODULAR',
    PFS_B_RACK: 'PFS_B_RACK' // Specific to INSC
};

const AISLE_GENERATION_OPTIONS = {
    COLUMN_RANGE: {
        allowAisleTypeOverride: false,
        hideColumnInputs: false,
        aisleConfigGeneratorName: RackBasedGenerator.getName()
    },
    AISLE_OVERRIDE: {
        allowAisleTypeOverride: true,
        hideColumnInputs: true,
        aisleConfigGeneratorName: AisleBasedGenerator.getName()
    },
    IN_SC_MANUAL_ENTRY: {
        allowAisleTypeOverride: false,
        hideColumnInputs: true,
        aisleConfigGeneratorName: StackingFilterBinAisleGenerator.getName(),
        shouldEnterBinLabel: true,
        aisleNamePatternOverride: '^[A-Z]+-?[0-9]+(-[0-9])?$',
        aisleNamePlaceholderOverride: 'Chute1 or Chute1-1',
        rowsInRack: 2,
        colsInRack: 3
    }
};

const getRackType = async(siteId) => {
    try {
        const siteInfo = await SitePlanningApi.loadSiteInfo(siteId);
        return siteInfo.rackType || RACK_LAYOUT_TYPES.MODULAR;
    } catch (e) {
        return RACK_LAYOUT_TYPES.MODULAR;
    }
};

export const getAisleGenerationSetting = async(siteId) => {
    const rackType = await getRackType(siteId);
    switch (rackType) {
        case RACK_LAYOUT_TYPES.EU_RACK:
            return AISLE_GENERATION_OPTIONS.COLUMN_RANGE;
        case RACK_LAYOUT_TYPES.PFS_B_RACK:
            return AISLE_GENERATION_OPTIONS.IN_SC_MANUAL_ENTRY;
        case RACK_LAYOUT_TYPES.MODULAR:
        case RACK_LAYOUT_TYPES.BAKER:
        default:
            return AISLE_GENERATION_OPTIONS.AISLE_OVERRIDE;
    }
};
