import {CHANGE_LED_CONTROLLER_TYPE} from '../../actions/actionTypes';

export default (state = null, action) => {
    switch (action.type) {
        case CHANGE_LED_CONTROLLER_TYPE:
            return action.ledControllerType;
        default:
            return state;
    }
};
