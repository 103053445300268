import {combineReducers} from 'redux';
import {
    TOGGLE_ADD_MULTIPLE_AISLES_MODAL,
    OPEN_DUPLICATE_CONTROLLER_MODAL,
    CLOSE_DUPLICATE_CONTROLLER_MODAL,
    OPEN_CHANGE_SITE_MODAL,
    CLOSE_CHANGE_SITE_MODAL,
    OPEN_LOADING_MODAL,
    CLOSE_LOADING_MODAL,
    TOGGLE_CONFIRM_ACTION_MODAL
} from '../actions/actionTypes';

function addMultipleAislesModalStatusReducer(state = {show: false}, action) {
    switch (action.type) {
        case TOGGLE_ADD_MULTIPLE_AISLES_MODAL:
            return {show: action.show};
        default:
            return state;
    }
}

function duplicateControllerModalStatusReducer(state = {show: false, sourceControllerId: null}, action) {
    switch (action.type) {
        case OPEN_DUPLICATE_CONTROLLER_MODAL:
            return {show: true, sourceControllerId: action.sourceControllerId};
        case CLOSE_DUPLICATE_CONTROLLER_MODAL:
            return {show: false, sourceControllerId: null};
        default:
            return state;
    }
}

function changeSiteModalStatusReducer(state = {show: false}, action) {
    switch (action.type) {
        case OPEN_CHANGE_SITE_MODAL:
            return {show: true};
        case CLOSE_CHANGE_SITE_MODAL:
            return {show: false};
        default:
            return state;
    }
}

function loadingModalReducer(state = {show: false, message: ''}, action) {
    switch (action.type) {
        case OPEN_LOADING_MODAL:
            return {show: true, message: action.message};
        case CLOSE_LOADING_MODAL:
            return {show: false, message: ''};
        default:
            return state;
    }
}

function confirmActionModalReducer(state = {show: false}, action) {
    switch (action.type) {
        case TOGGLE_CONFIRM_ACTION_MODAL:
            return {
                show: action.show,
                prompt: action.prompt,
                onConfirmAction: action.onConfirmAction,
                data: action.data
            };
        default:
            return state;
    }
}

export default combineReducers({
    addMultipleAisles: addMultipleAislesModalStatusReducer,
    duplicateController: duplicateControllerModalStatusReducer,
    changeSiteModal: changeSiteModalStatusReducer,
    loadingModal: loadingModalReducer,
    confirmActionModal: confirmActionModalReducer
});
