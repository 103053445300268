import React from 'react';
import './Controllers.css';
import {Accordion, Container} from 'react-bootstrap';
import '../LEDController/LEDController';
import LEDController from '../LEDController/LEDController';
import {connect} from 'react-redux';

class Controllers extends React.Component {
    render() {
        return <Container className="controllers">
            <Accordion activeKey={this.props.openControllerId}>
                {
                    this.props.controllers.map((controller, index) => (
                        <LEDController controllerId={index} key={index}/>
                    ))
                }
            </Accordion>
        </Container>;
    }
}

function mapStateToProps(state) {
    return {
        openControllerId: state.expandedController,
        controllers: state.siteConfig.controllers
    };
}

export default connect(mapStateToProps, null)(Controllers);
