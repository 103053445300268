import AbstractAisleGenerator from './AbstractAisleGenerator';
import {COLUMN_ALPHABETS} from '../../constants';
export const ALL_BARS_LABEL = 'ALL_BARS';

class RackBasedGenerator extends AbstractAisleGenerator {
    static getName() {
        return 'RackBasedGenerator';
    }

    generateAisle(macAddress, aisle, aisleConfig) {
        const {name: aisleName, port, startColumn, endColumn, missingColumns: missingColumnsString} = aisle;
        const entities = [];
        const colOrder = this.generateColOrder(startColumn, endColumn, missingColumnsString);
        const templateParameters = aisleConfig.parameters;
        // TODO: validate that the template has required parameters. Throw otherwise

        const columnsPerRack = templateParameters.columns.length;
        const pixelsPerRack = this.getAislePixelCount(aisleConfig);
        let rackStartPixelOffset = 0;
        const allBarEntity = {
            label: ALL_BARS_LABEL,
            segments: []
        };

        for (let columnOffset = 0; columnOffset < colOrder.length; columnOffset += columnsPerRack) {
            const columnsForRack = colOrder.slice(columnOffset, columnOffset + columnsPerRack);
            for (const entityTemplate of aisleConfig.entities) {
                const segments = [];
                for (const segmentTemplate of entityTemplate.segments) {
                    segments.push(this.generateSegment(segmentTemplate, rackStartPixelOffset));
                }

                if (this.isAllBarsSegment(entityTemplate)) {
                    allBarEntity.segments = allBarEntity.segments.concat(segments);
                } else {
                    let label = entityTemplate.labelFormat.replace('{aisle}', aisleName);
                    for (let columnParameterIndex = 0; columnParameterIndex < columnsForRack.length; columnParameterIndex += 1) {

                        label = label.replace(`{columns:${columnParameterIndex}}`, columnsForRack[columnParameterIndex]);
                    }
                    entities.push({
                        label, segments
                    });
                }
            }
            rackStartPixelOffset += pixelsPerRack;
        }
        entities.push(allBarEntity);

        return {
            label: aisleName,
            macAddress,
            port: parseInt(port, 10),
            entities
        };
    }

    generateColOrder(startColumn, endColumn, missingColumnsString) {
        const missingColumns = missingColumnsString.split(',').map((column) => column.trim().toUpperCase());
        const startColumnIndex = COLUMN_ALPHABETS.indexOf(startColumn.toUpperCase());
        const endColumnIndex = COLUMN_ALPHABETS.indexOf(endColumn.toUpperCase());
        const colOrder = [];
        for (let i = Math.min(startColumnIndex, endColumnIndex); i <= Math.max(startColumnIndex, endColumnIndex); i += 1) {
            const column = COLUMN_ALPHABETS[i];
            if (!missingColumns.includes(column)) {
                colOrder.push(column);
            }
        }
        if (startColumnIndex > endColumnIndex) {
            colOrder.reverse();
        }
        return colOrder;
    }

    generateSegment(segmentTemplate, rackStartPixelOffset) {
        return Object.assign({}, segmentTemplate, {
            startIndex: segmentTemplate.startIndex + rackStartPixelOffset,
            endIndex: segmentTemplate.endIndex + rackStartPixelOffset
        });
    }

    isAllBarsSegment(entity) {
        return entity.labelFormat === ALL_BARS_LABEL;
    }

    getAislePixelCount(aisleConfig) {
        // find from ALL_BARs
        const allBarEntity = aisleConfig.entities.find(this.isAllBarsSegment);
        // TODO: throw if allBarEntity is not found
        let maxIndex = 0;
        for (const segment of allBarEntity.segments) {
            maxIndex = Math.max(maxIndex, segment.startIndex);
            maxIndex = Math.max(maxIndex, segment.endIndex);
        }

        return maxIndex + 1;
    }
}

export default RackBasedGenerator;
