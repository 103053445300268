/* eslint-disable camelcase */
import Amplify from 'aws-amplify';
import {API_NAMES} from '../constants';

function configureAmplify({region, userPoolId, userPoolWebClientId, apiConfig, oauthConfig}) {
    const oauth = oauthConfig && {
        domain: oauthConfig.domain,
        scope: ['email', 'openid', 'profile'],
        redirectSignIn: oauthConfig.redirectUrl,
        redirectSignOut: oauthConfig.redirectUrl,
        responseType: 'code',
        label: 'Amazon Employee Sign in'
    };

    Amplify.configure({
        aws_cognito_region: region,
        Auth: {
            identityPoolRegion: region,
            userPoolId,
            userPoolWebClientId,
            mandatorySignIn: true,
            oauth
        },
        API: {
            endpoints: Object.keys(API_NAMES).map((apiName) => ({
                name: API_NAMES[apiName],
                endpoint: apiConfig[API_NAMES[apiName]].endpoint
            }))
        }
    });
}

export default configureAmplify;
