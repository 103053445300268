import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {loadAppConfig, getAppConfig} from './configs/app-config';
import configureAmplify from './configs/configure-amplify';

async function main() {
    await loadAppConfig();
    const appConfig = getAppConfig();
    configureAmplify(appConfig);
    ReactDOM.render(<App />, document.getElementById('root'));
    serviceWorker.register();
}

main();
