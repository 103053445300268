import React from 'react';
import { Logger } from 'aws-amplify';
import {connect} from 'react-redux';

import Header from '../Header/Header';
import DataBox from '../DataBox/DataBox';
import AddMultipleAisleModal from '../Modal/AddMultipleAIsleModal';
import DuplicateControllerModal from '../Modal/DuplicateControllerModal';
import { updateAisleTypes, updateLedControllerTypes } from '../../actions/resourceActions';
import * as SitePlanningApi from '../../apis/site-planning-api';
import ChangeSiteModal from '../Modal/ChangeSiteModal';
import {changeSiteId} from '../../actions/siteLevelActions';
import {loadFromCloud} from '../../actions/siteConfigActions';
import {isValidStationName} from '../../validations/validations';
import { push } from 'connected-react-router';
import LoadingModal from '../Modal/LoadingModal';
import {Prompt} from 'react-router';
import ConfirmActionModal from '../Modal/ConfirmActionModal';

const LOG = new Logger('SitePlanningPage');
const UNSAVED_BEFORE_UNLOAD_RETURN_VALUE = 'You have unsaved changes';

class SitePlanningPage extends React.Component {
    constructor(props) {
        super(props);
        this.syncSiteIdFromUrlToInternalState();
        this.loadAisleTypes();
        this.loadLedControllerTypes();
    }

    componentDidMount() {
        window.addEventListener('beforeunload', (event) => {
            if (!this.props.saved) {
                event.preventDefault();
                event.returnValue = UNSAVED_BEFORE_UNLOAD_RETURN_VALUE;
                return UNSAVED_BEFORE_UNLOAD_RETURN_VALUE;
            }
            return null;
        });
    }

    syncSiteIdFromUrlToInternalState() {
        const urlSiteId = this.props.match.params.siteId || '';
        // re-write url and do nothing if urlSiteId is invalid
        if (urlSiteId !== '' && !isValidStationName(urlSiteId)) {
            this.props.changeUrlSiteId('');
        } else {
            // set internal siteId to url siteId
            LOG.debug(`current siteId=${this.props.siteId}, new=${urlSiteId}`);
            this.props.changeInternalSiteId(urlSiteId);
        }
    }

    async loadAisleTypes() {
        try {
            const aisleTypes = await SitePlanningApi.listAisleTypes(this.props.match.params.siteId);
            const aisleTypesByS3Key = {};
            for (const config of aisleTypes.configs) {
                aisleTypesByS3Key[config.s3Key] = config;
            }
            this.props.updateAisleTypes(aisleTypesByS3Key);
        } catch (e) {
            LOG.error('Failed to load aisle types. ', e);
        }
    }

    async loadLedControllerTypes() {
        try {
            const output = await SitePlanningApi.listLedControllerTypes();
            let configsByS3Key = {};

            if (output.ledControllerConfigs) {
                // backward compatibility
                configsByS3Key = output.ledControllerConfigs;
            } else {
                for (const config of output.configs) {
                    configsByS3Key[config.s3Key] = config;
                }
            }
            this.props.updateLedControllerTypes(configsByS3Key);
        } catch (e) {
            LOG.error('Failed to load LED controller types. ', e);
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.match.params.siteId !== this.props.match.params.siteId) {
            this.syncSiteIdFromUrlToInternalState();
        } else if (prevProps.siteId !== this.props.siteId) {
            // load from cloud
            this.props.loadSiteConfigFromCloud();
        }
    }

    render() {
        LOG.debug(this.props);
        return <div className="whole-view">
            <Header/>
            <DataBox/>
            <LoadingModal/>
            <AddMultipleAisleModal/>
            <DuplicateControllerModal/>
            <ChangeSiteModal urlSiteId={this.props.match.params.siteId}/>
            <ConfirmActionModal/>
            <Prompt when={!this.props.saved} message='Leave without saving?'/>
        </div>;
    }
}

function mapStateToProps(state) {
    return {
        siteId: state.siteConfig && state.siteConfig.stationName !== null
            ? state.siteConfig.stationName
            : '',
        saved: state.saved
    };
}

function mapDispatchToProps(dispatch) {
    return {
        updateAisleTypes: (aisleTypes) => dispatch(updateAisleTypes(aisleTypes)),
        updateLedControllerTypes: (ledControllerTypes) => dispatch(updateLedControllerTypes(ledControllerTypes)),
        changeInternalSiteId: (siteId) => dispatch(changeSiteId(siteId)),
        changeUrlSiteId: (siteId) => dispatch(push(`/site/${siteId}`)),
        loadSiteConfigFromCloud: () => dispatch(loadFromCloud())
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(SitePlanningPage);
