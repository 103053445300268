// LED CONTROLLER Actions
export const ADD_LED_CONTROLLER = 'ADD_LED_CONTROLLER';
export const DELETE_LED_CONTROLLER = 'DELETE_LED_CONTROLLER';
export const TOGGLE_LED_CONTROLLER = 'TOGGLE_LED_CONTROLLER';
export const EXPAND_LED_CONTROLLER = 'EXPAND_LED_CONTROLLER';
export const SAVE_FIRST_INVALID_AISLE = 'SAVE_FIRST_INVALID_AISLE';
export const CHANGE_MAC_ADDRESS = 'CHANGE_MAC_ADDRESS';
export const DUPLICATE_LED_CONTROLLER = 'DUPLICATE_LED_CONTROLLER';
export const SPLIT_LED_CONTROLLER = 'SPLIT_LED_CONTROLLER';
export const REVERSE_PORT_COLUMN = 'REVERSE_PORT_COLUMN';

// Aisle Actions
export const ADD_SINGLE_AISLE = 'ADD_SINGLE_AISLE';
export const ADD_MULTIPLE_AISLES = 'ADD_MULTIPLE_AISLES';
export const COPY_AISLE = 'COPY_AISLE';
export const PASTE_AISLE = 'PASTE_AISLE';
export const DELETE_AISLE = 'DELETE_AISLE';
export const CHANGE_AISLE_DATA = 'CHANGE_AISLE_DATA';
export const CHANGE_AISLE_TYPE = 'CHANGE_AISLE_TYPE';
export const CHANGE_AISLE_WORKSPACE = 'CHANGE_AISLE_WORKSPACE';
export const ADD_RACK_TO_AISLE = 'ADD_RACK_TO_AISLE';
export const DELETE_RACK_FROM_AISLE = 'DELETE_RACK_FROM_AISLE';
export const CHANGE_RACK_DATA = 'CHANGE_RACK_DATA';
export const UPDATE_RACK_DIMENSIONS = 'UPDATE_RACK_DIMENSIONS';

// STATION Level Actions
export const CHANGE_STATION = 'CHANGE_STATION';
export const LOAD_STATION = 'LOAD_STATION';
export const CHANGE_RACK_TYPE = 'CHANGE_RACK_TYPE';
export const CHANGE_COLOR_ORDER = 'CHANGE_COLOR_ORDER';
export const CHANGE_AISLE_GENERATION_SETTING = 'CHANGE_AISLE_GENERATION_SETTING';
export const CHANGE_LED_CONTROLLER_TYPE = 'CHANGE_LED_CONTROLLER_TYPE';
export const CHANGE_SITE_DEFAULT_AISLE_TYPE = 'CHANGE_SITE_DEFAULT_AISLE_TYPE';
export const UPDATE_AISLE_GENERATION_DIMENSIONS = 'UPDATE_AISLE_GENERATION_DIMENSIONS';

// Notification Actions
export const FORM_VALIDATED = 'FORM_VALIDATED';
export const SHOW_PROMPT = 'SHOW_PROMPT';
export const DISMISS_PROMPT = 'DISMISS_PROMOPT';

// Add-multiple-aisle-modal Actions
export const TOGGLE_ADD_MULTIPLE_AISLES_MODAL = 'TOGGLE_ADD_MULTIPLE_AISLES_MODAL';

// Duplicate-controller-modal Actions
export const OPEN_DUPLICATE_CONTROLLER_MODAL = 'OPEN_DUPLICATE_CONTROLLER_MODAL';
export const CLOSE_DUPLICATE_CONTROLLER_MODAL = 'CLOSE_DUPLICATE_CONTROLLER_MODAL';

// Change-site-modal actions
export const OPEN_CHANGE_SITE_MODAL = 'OPEN_CHANGE_SITE_MODAL';
export const CLOSE_CHANGE_SITE_MODAL = 'CLOSE_CHANGE_SITE_MODAL';

// Confirm Aisle Change Modal Actions
export const TOGGLE_CONFIRM_ACTION_MODAL = 'TOGGLE_CONFIRM_ACTION_MODAL';

// Site config import and export actions
export const IMPORT_SITE_CONFIG_FROM_FILE = 'IMPORT_SITE_CONFIG_FROM_FILE';
export const RESET_SITE_CONFIG = 'RESET_SITE_CONFIG';

// Loading modal actions
export const OPEN_LOADING_MODAL = 'OPEN_LOADING_MODAL';
export const CLOSE_LOADING_MODAL = 'CLOSE_LOADING_MODAL';

// Resource Actions
export const UPDATE_AISLE_TYPES = 'UPDATE_AISLE_TYPES';
export const FILTER_AISLE_TYPES = 'FILTER_AISLE_TYPES';
export const UPDATE_LED_CONTROLLER_TYPES = 'UPDATE_LED_CONTROLLER_TYPES';
export const FILTER_LED_CONTROLLER_TYPES = 'FILTER_LED_CONTROLLER_TYPES';

// app state Actions
export const CONFIG_SAVED = 'CONFIG_SAVED';

//workspace Actions
export const CREATE_WORKSPACE = 'CREATE_WORKSPACE';
export const DELETE_WORKSPACE = 'DELETE_WORKSPACE';

// Site config import and export actions
export const IMPORT_CONFIG_FROM_FILE = 'IMPORT_CONFIG_FROM_FILE';