import {API} from 'aws-amplify';
import {API_NAMES} from '../constants';
import {getJwtToken} from "./auth";

const aisleConfigCache = {};

async function listAisleTypes(stationName) {
    const jwtToken = await getJwtToken();
    return await API.get(API_NAMES.sitePlanningApi, `/aisleConfig/${stationName}/loadAisle`, {
        headers: { Authorization: `Bearer ${jwtToken}` }
    });
}

async function loadAisleConfig(s3Key) {
    if (s3Key in aisleConfigCache) {
        return aisleConfigCache[s3Key];
    }
    const jwtToken = await getJwtToken();

    aisleConfigCache[s3Key] = await API.get(API_NAMES.sitePlanningApi, `/aisleConfig/${s3Key}`, {
        headers: { Authorization: `Bearer ${jwtToken}` }
    });

    return aisleConfigCache[s3Key];
}

async function listLedControllerTypes() {
    const jwtToken = await getJwtToken();

    return await API.get(API_NAMES.sitePlanningApi, '/ledControllerConfig', {
        headers: { Authorization: `Bearer ${jwtToken}` }
    });
}

async function saveSiteConfig(stationName, siteConfig) {
    const jwtToken = await getJwtToken();

    return await API.put(API_NAMES.sitePlanningApi, `/siteConfig/${stationName}`, {
        headers: {
            Authorization: `Bearer ${jwtToken}`
        },
        body: siteConfig
    });
}

async function uploadPixelConfig(stationName, configFile) {
    const jwtToken = await getJwtToken();

    return await API.put(API_NAMES.sitePlanningApi, `/pixelConfig/${stationName}/uploadConfig`, {
        headers: {
            Authorization: `Bearer ${jwtToken}`
        },
        body: configFile
    });
}

async function loadPixelConfig(stationName) {
    const jwtToken = await getJwtToken();

    return await API.get(API_NAMES.sitePlanningApi, `/pixelConfig/${stationName}/loadConfig`, {
        headers: {
            Authorization: `Bearer ${jwtToken}`
        }
    });
}

async function createPixelConfig(stationName) {
    const jwtToken = await getJwtToken();

    return await API.get(API_NAMES.sitePlanningApi, `/pixelConfig/${stationName}/createConfig`, {
        headers: {
            Authorization: `Bearer ${jwtToken}`
        }
    });
}

async function loadSiteConfig(stationName) {
    const jwtToken = await getJwtToken();

    return await API.get(API_NAMES.sitePlanningApi, `/siteConfig/${stationName}`, {
        headers: {
            Authorization: `Bearer ${jwtToken}`
        }
    });
}

async function deploySiteConfig(stationName, siteConfig) {
    const jwtToken = await getJwtToken();

    return await API.post(API_NAMES.sitePlanningApi, `/siteConfig/${stationName}/deployment`, {
        headers: {
            Authorization: `Bearer ${jwtToken}`
        },
        body: siteConfig
    });
}

async function loadSiteInfo(stationName) {
    const jwtToken = await getJwtToken();

    return await API.get(API_NAMES.sitePlanningApi, `/siteConfig/${stationName}/rackType`, {
        headers: {
            Authorization: `Bearer ${jwtToken}`
        }
    });
}

async function prepopulateSiteConfig(stationName) {
    const jwtToken = await getJwtToken();

    return await API.get(API_NAMES.sitePlanningApi, `/siteConfig/${stationName}/prepopulate`, {
        headers: {
            Authorization: `Bearer ${jwtToken}`
        }
    });
}

export {
    listAisleTypes,
    listLedControllerTypes,
    saveSiteConfig,
    loadSiteConfig,
    deploySiteConfig,
    loadAisleConfig,
    prepopulateSiteConfig,
    loadSiteInfo,
    uploadPixelConfig,
    loadPixelConfig,
    createPixelConfig
};
