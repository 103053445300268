import {
    ADD_LED_CONTROLLER,
    SPLIT_LED_CONTROLLER,
    REVERSE_PORT_COLUMN,
    ADD_MULTIPLE_AISLES,
    ADD_SINGLE_AISLE,
    CHANGE_AISLE_DATA,
    CHANGE_MAC_ADDRESS,
    CHANGE_RACK_TYPE,
    COPY_AISLE,
    DELETE_AISLE,
    DELETE_LED_CONTROLLER,
    PASTE_AISLE,
    TOGGLE_LED_CONTROLLER,
    TOGGLE_ADD_MULTIPLE_AISLES_MODAL,
    OPEN_DUPLICATE_CONTROLLER_MODAL,
    CLOSE_DUPLICATE_CONTROLLER_MODAL,
    DUPLICATE_LED_CONTROLLER,
    CHANGE_AISLE_TYPE,
    OPEN_CHANGE_SITE_MODAL,
    CLOSE_CHANGE_SITE_MODAL,
    CREATE_WORKSPACE,
    DELETE_WORKSPACE,
    ADD_RACK_TO_AISLE,
    DELETE_RACK_FROM_AISLE,
    CHANGE_RACK_DATA,
    CHANGE_AISLE_WORKSPACE,
    TOGGLE_CONFIRM_ACTION_MODAL
} from './actionTypes';

export function addLEDController() {
    return (dispatch, getState) => {
        const state = getState();
        dispatch({
            type: ADD_LED_CONTROLLER,
            newControllerId: state.siteConfig.controllers.length
        });
    };
}

export function deleteLEDController(controllerId) {
    return {type: DELETE_LED_CONTROLLER, controllerId};
}

export function toggleLEDController(controllerId) {
    return {type: TOGGLE_LED_CONTROLLER, controllerId};
}

export function addSingleAisle(controllerId) {
    return (dispatch, getState) => {
        const aisleGenerationSetting = getState().aisleGenerationSetting;
        if (aisleGenerationSetting.shouldEnterBinLabel) {
            dispatch({type: ADD_SINGLE_AISLE, controllerId,
                rows: aisleGenerationSetting.rowsInRack,
                cols: aisleGenerationSetting.colsInRack});
        } else {
            dispatch({type: ADD_SINGLE_AISLE, controllerId});
        }
    };
}

export function addMultipleAisles(controllerId, payload) {
    return (dispatch, getState) => {
        const aisleGenerationSetting = getState().aisleGenerationSetting;
        if (aisleGenerationSetting.shouldEnterBinLabel) {
            dispatch({type: ADD_MULTIPLE_AISLES, controllerId, payload,
                rows: aisleGenerationSetting.rowsInRack,
                cols: aisleGenerationSetting.colsInRack});
        } else {
            dispatch({type: ADD_MULTIPLE_AISLES, controllerId, payload});
        }
    };
}

export function changeMacAddress(controllerId, macAddress) {
    return {type: CHANGE_MAC_ADDRESS, controllerId, macAddress};
}

export function copyAisle(controllerId, aisleId) {
    return (dispatch, getState) => {
        const state = getState();
        const copiedAisle = state.siteConfig.controllers[controllerId].aisles[aisleId];
        dispatch({
            type: COPY_AISLE,
            clipboard: {
                startColumn: copiedAisle.startColumn,
                endColumn: copiedAisle.endColumn,
                missingColumns: copiedAisle.missingColumns,
                aisleType: copiedAisle.aisleType
            }
        });
    };
}

export function pasteAisle(controllerId, aisleIndex) {
    return (dispatch, getState) => {
        const state = getState();
        dispatch({
            type: PASTE_AISLE,
            controllerId,
            aisleIndex,
            clipboard: {
                ...state.clipboard
            }
        });
    };
}

export function deleteAisle(controllerId, aisleIndex) {
    return {type: DELETE_AISLE, controllerId, aisleIndex};
}

export function changeAisleData(controllerId, aisleIndex, data) {
    return {type: CHANGE_AISLE_DATA, controllerId, aisleIndex, data};
}

export function addRackToAisle(controllerId, aisleIndex) {
    return (dispatch, getState) => {
        const aisleGenerationSetting = getState().aisleGenerationSetting;
        dispatch({type: ADD_RACK_TO_AISLE, controllerId, aisleIndex,
            rows: aisleGenerationSetting.rowsInRack,
            cols: aisleGenerationSetting.colsInRack});
    };
}

export function deleteRackFromAisle(controllerId, aisleIndex, rackIndex) {
    return {type: DELETE_RACK_FROM_AISLE, controllerId, aisleIndex, rackIndex};
}

export function changeRackData(controllerId, aisleIndex, rackIndex, data) {
    return {type: CHANGE_RACK_DATA, controllerId, aisleIndex, rackIndex, data};
}


export function changeRackType(rackType) {
    return {type: CHANGE_RACK_TYPE, rackType};
}

export function toggleAddAislesModal(show) {
    return {type: TOGGLE_ADD_MULTIPLE_AISLES_MODAL, show};
}

export function openDuplicateControllerModal(sourceControllerId) {
    return {type: OPEN_DUPLICATE_CONTROLLER_MODAL, sourceControllerId};
}

export function closeDuplicateControllerModal() {
    return {type: CLOSE_DUPLICATE_CONTROLLER_MODAL};
}

export function duplicateController(sourceControllerId, startAisle, macAddress) {
    return {
        type: DUPLICATE_LED_CONTROLLER,
        payload: {sourceControllerId, startAisle, macAddress}
    };
}

// Split the original controller into 2 controllers, with all splitIndex as the first split aisle.
export function splitControllerAisles(sourceControllerId, splitIndex) {
    return {
        type: SPLIT_LED_CONTROLLER,
        payload: {sourceControllerId, splitIndex}
    };
}

export function reversePortColumn(controllerId) {
    return {
        type: REVERSE_PORT_COLUMN,
        payload: {controllerId}
    };
}

export function openChangeSiteModal() {
    return {
        type: OPEN_CHANGE_SITE_MODAL
    };
}

export function closeChangeSiteModal() {
    return {
        type: CLOSE_CHANGE_SITE_MODAL
    };
}

export function changeAisleType(controllerId, aisleIndex, aisleType) {
    return {
        type: CHANGE_AISLE_TYPE,
        controllerId,
        aisleIndex,
        aisleType
    };
}

function createOption(workspaceName) {
    return {
        label: workspaceName,
        value: workspaceName
    };
}

export function createWorkSpace(workspaceName) {
    return {
        type: CREATE_WORKSPACE,
        newOption: createOption(workspaceName)
    };
}

export function confirmDeleteWorkSpace(workSpaceName, value) {
    return async(dispatch, getState) => {
        const prompt = 'Are you sure you want to delete workspace: ' + workSpaceName + '?';
        dispatch(showConfirmActionModal(value, prompt, deleteWorkSpace));
    };
}

export function deleteWorkSpace() {
    return async(dispatch, getState) => {
        const workSpaceName = getState().modalStatus.confirmActionModal.data;
        dispatch({
            type: DELETE_WORKSPACE,
            workSpaceName: workSpaceName
        });
        dispatch(closeConfirmActionModal());
    };
}

export function changeAisleWorkspace(controllerId, aisleIndex, workspace) {
    return {
        type: CHANGE_AISLE_WORKSPACE,
        controllerId,
        aisleIndex,
        workspace
    };
}

export function showConfirmActionModal(data, prompt, onConfirmAction) {
    return {
        type: TOGGLE_CONFIRM_ACTION_MODAL,
        prompt: prompt,
        onConfirmAction: onConfirmAction,
        data: data,
        show: true
    };
}

export function closeConfirmActionModal() {
    return {
        type: TOGGLE_CONFIRM_ACTION_MODAL,
        show: false
    };
}
