class AbstractAisleGenerator {
    generateAisle(macAddress, aisleParameters, aisleTemplate, workspaceToWalkWayMapping) {
        throw new Error('NotImplemented');
    }

    static getName() {
        throw new Error('NotImplemented');
    }
}

export default AbstractAisleGenerator;
