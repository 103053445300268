export const AISLE_NAME_PATTERN = '^[A-Z]+-?[0-9]*$';
export const STATION_NAME_PATTERN = '^[A-Z][A-Z][A-Z0-9]([A-Z0-9]+)$';
export const AISLE_MISSING_COL_PATTERN = '^[A-Z](,[A-Z])*$';
export const MAC_ADDRESS_PATTERN = '^([0-9a-f]{2}[:]){5}([0-9a-f]{2})$';
export const BUFFER = 'buffer';
export const CONTROLLER_PREFIX = 'ledController';
export const DASH = '-';

export const status = {
    None: 1,
    Processing: 2,
    Completed: 3
};

export const rackTypes = ['NA_normal', 'Overflow'];

export const COLUMN_ALPHABETS = [
    'A', 'B', 'C', 'D', 'E', 'G', // Skip F
    'H', 'J', 'K', 'L', 'M', 'N', // Skip I
    'O', 'P', 'Q', 'R', 'S', 'T',
    'U', 'V', 'W', 'X', 'Y', 'Z'
];

export const PROMPT_LEVELS = {
    INFO: 'INFO',
    WARN: 'WARN',
    ERROR: 'ERROR'
};

export const API_NAMES = {
    sitePlanningApi: 'sitePlanningApi',
    siteManagementApi: 'siteManagementApi'
};

export const INSC_LED_CONTROLLER_MODELS = new Set([
    'led-controller-config/falcon-48-port-unicast-ledcontroller-config.json'
]);
