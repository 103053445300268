import debounce from 'debounce';
import store from './store';

export const storageKey = 'previousSession';
export const debounceWait = 1000;

export function getPreviousSession() {
    return {};
    /*
    const previousState = localStorage.getItem(storageKey) ? JSON.parse(localStorage.getItem(storageKey)) : {};
    const {siteConfig} = previousState;
    return {
        siteConfig
    };
    */
}

export const cachingSubscriber = debounce(() => {
    localStorage.setItem(storageKey, JSON.stringify(store.getState()));
    console.log('state stored');
}, debounceWait);
