import aisleGeneratorClasses from '../aisleConfigGenerators';

class SiteConfigV2Generator {
    constructor(sitePlanningApi) {
        this.sitePlanningApi = sitePlanningApi;
        this.aisleConfigsByS3Key = new Map();
        this.aisleConfigGeneratorMap = {};
        this.aisleConfigGenerator = null;
    }

    setAisleConfigGenerator(aisleConfigGeneratorName) {
        if (!this.aisleConfigGeneratorMap[aisleConfigGeneratorName]) {
            this.aisleConfigGeneratorMap[aisleConfigGeneratorName] = new aisleGeneratorClasses[aisleConfigGeneratorName]();
        }
        this.aisleConfigGenerator = this.aisleConfigGeneratorMap[aisleConfigGeneratorName];
    }

    generateAisle(macAddress, aisle, aisleConfig, workspaceToWalkWayMapping) {
        return this.aisleConfigGenerator.generateAisle(macAddress, aisle, aisleConfig, workspaceToWalkWayMapping);
    }

    async getAisleConfig(s3Key) {
        if (!this.aisleConfigsByS3Key.has(s3Key)) {
            const aisleConfig = await this.sitePlanningApi.loadAisleConfig(s3Key);
            this.aisleConfigsByS3Key.set(s3Key, aisleConfig);
        }
        return this.aisleConfigsByS3Key.get(s3Key);
    }

    async generateController({macAddress, aisles}, siteDefaultAisleConfig, workspaceToWalkWayMapping) {
        const controllerLayout = [];
        for (const aisle of aisles) {
            const aisleConfig = aisle.aisleType == null
                ? siteDefaultAisleConfig
                : await this.getAisleConfig(aisle.aisleType.s3Key);
            controllerLayout.push(this.generateAisle(macAddress, aisle, aisleConfig, workspaceToWalkWayMapping));
        }

        return controllerLayout;
    }

    createWalkwayMapping(workspaces) {
        const mapping = {};
        if (!workspaces) {
            return mapping;
        }
        const cluster = 'A-';
        for (let i = 0; i < workspaces.length; i++) {
            const aisleIndex = (i * 3) + 1;
            mapping[workspaces[i].value] = cluster + aisleIndex + '_' + cluster + (aisleIndex + 1);
        }
        return mapping;
    }

    async generateLedStrips(siteConfigState) {
        const siteDefaultAisleConfigReference = siteConfigState.aisleType;
        const workspaceToWalkWayMapping = this.createWalkwayMapping(siteConfigState.workspaces);
        const siteDefaultAisleConfig = siteDefaultAisleConfigReference != null
            ? await this.getAisleConfig(siteDefaultAisleConfigReference.s3Key)
            : null;
        let ledStrips = [];
        for (const controllerState of siteConfigState.controllers) {
            // convert controllerState
            const ledStripsForController
                = await this.generateController(controllerState, siteDefaultAisleConfig, workspaceToWalkWayMapping);
            ledStrips = ledStrips.concat(ledStripsForController);
        }
        return ledStrips;
    }

    extractS3BasedConfig(s3BasedConfigState) {
        return s3BasedConfigState ? {
            s3Bucket: s3BasedConfigState.s3Bucket,
            s3Key: s3BasedConfigState.s3Key
        } : undefined;
    }

    async generateSiteConfig(siteConfigState) {
        const ledStrips = await this.generateLedStrips(siteConfigState);
        return {
            siteId: siteConfigState.stationName,
            generationParameters: siteConfigState,
            colorOrder: siteConfigState.colorOrder,
            ledControllerConfig: this.extractS3BasedConfig(siteConfigState.ledControllerType),
            ledStrips
        };
    }
}

export default SiteConfigV2Generator;
