import {CHANGE_SITE_DEFAULT_AISLE_TYPE} from '../../actions/actionTypes';

export default (state = null, action) => {
    switch (action.type) {
        case CHANGE_SITE_DEFAULT_AISLE_TYPE:
            return action.aisleType;
        default:
            return state;
    }
};
