import {
    CHANGE_SITE_DEFAULT_AISLE_TYPE,
    CHANGE_LED_CONTROLLER_TYPE,
    CHANGE_COLOR_ORDER,
    CHANGE_STATION,
    CHANGE_AISLE_GENERATION_SETTING, UPDATE_RACK_DIMENSIONS, UPDATE_AISLE_GENERATION_DIMENSIONS, RESET_SITE_CONFIG
} from './actionTypes';
import {filterAisleTypes, filterLedControllerTypes} from './resourceActions';
import {getAisleGenerationSetting} from '../configs/aisle-generation-setting';
import { openLoadingModal, showPrompt} from './notificationActions';
import {PROMPT_LEVELS} from '../constants';
import { closeConfirmActionModal, showConfirmActionModal} from './actions';
import {loadAisleConfig} from '../apis/site-planning-api';

export function changeSiteDefaultAisleType(aisleType) {
    return async(dispatch, getState) => {
        const state = getState();
        const aisleGenerationSetting = state.aisleGenerationSetting;
        if (aisleType && aisleGenerationSetting.shouldEnterBinLabel) {
            loadAisleConfig(aisleType.s3Key); //preemptively cache the aisle config
            if (state.siteConfig.controllers.length > 0) {
                const prompt = 'Changing Aisle type may result in truncation of the Rack. Do you wish to continue?';
                dispatch(showConfirmActionModal(aisleType, prompt, confirmAisleTypeChange));
            } else {
                changeAisleType(aisleType, dispatch);
            }
        } else {
            dispatch({type: CHANGE_SITE_DEFAULT_AISLE_TYPE, aisleType});
            dispatch(closeConfirmActionModal());
        }
    };
}

async function changeAisleType(aisleType, dispatch) {
    const aisleConfig = await loadAisleConfig(aisleType.s3Key);
    const aisleAttributes = aisleConfig.attributes;
    if (aisleAttributes) {
        const {rows, cols} = aisleAttributes;
        dispatch({type: UPDATE_AISLE_GENERATION_DIMENSIONS, rows, cols});
        dispatch({type: UPDATE_RACK_DIMENSIONS, rows, cols});
    }
    dispatch({type: CHANGE_SITE_DEFAULT_AISLE_TYPE, aisleType});
    dispatch(closeConfirmActionModal());
}

export function confirmAisleTypeChange() {
    return async(dispatch, getState) => {
        const aisleType = getState().modalStatus.confirmActionModal.data;
        await changeAisleType(aisleType, dispatch);
    };
}

export function changeLedControllerType(ledControllerType) {
    return {type: CHANGE_LED_CONTROLLER_TYPE, ledControllerType};
}

export function changeColorOrder(colorOrder) {
    return {type: CHANGE_COLOR_ORDER, colorOrder};
}

export async function changeAisleGenerationSetting(siteId) {
    const setting = await getAisleGenerationSetting(siteId);
    return {type: CHANGE_AISLE_GENERATION_SETTING, setting};
}

export function changeSiteId(siteId) {
    return async(dispatch) => {
        let aisleGenerationSetting;
        try {
            dispatch({
                type: RESET_SITE_CONFIG
            });
            dispatch({type: CHANGE_STATION, siteId});
            if (siteId === '') {
                return;
            }
            dispatch(openLoadingModal('Setting Up')); // Open the loading modal here and closing it in loadFromCloud()
            aisleGenerationSetting = await changeAisleGenerationSetting(siteId);
            dispatch(aisleGenerationSetting);
            dispatch(filterAisleTypes());
            dispatch(filterLedControllerTypes());
        } catch (e) {
            dispatch(showPrompt({
                level: PROMPT_LEVELS.ERROR,
                header: 'Error loading site rack type',
                message: `Error loading rack type for site: ${siteId}. 
                Please import valid rack type into DynamoDB table SiteInfo.`
            }));
        }
    };
}
