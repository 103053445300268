import {
    ADD_LED_CONTROLLER,
    COPY_AISLE,
    EXPAND_LED_CONTROLLER,
    SPLIT_LED_CONTROLLER,
    REVERSE_PORT_COLUMN,
    FORM_VALIDATED,
    TOGGLE_LED_CONTROLLER,
    SHOW_PROMPT,
    DISMISS_PROMPT,
    CONFIG_SAVED,
    DELETE_LED_CONTROLLER,
    CHANGE_MAC_ADDRESS,
    DUPLICATE_LED_CONTROLLER,
    ADD_SINGLE_AISLE,
    ADD_MULTIPLE_AISLES,
    PASTE_AISLE,
    DELETE_AISLE,
    CHANGE_AISLE_DATA,
    CHANGE_AISLE_TYPE,
    RESET_SITE_CONFIG,
    IMPORT_SITE_CONFIG_FROM_FILE,
    CHANGE_COLOR_ORDER,
    CHANGE_LED_CONTROLLER_TYPE,
    CHANGE_SITE_DEFAULT_AISLE_TYPE,
    SAVE_FIRST_INVALID_AISLE,
    ADD_RACK_TO_AISLE,
    CHANGE_RACK_DATA,
    DELETE_RACK_FROM_AISLE, CHANGE_AISLE_WORKSPACE
} from '../actions/actionTypes';
import {combineReducers} from 'redux';
import modalReducer from './modalReducer';
import siteConfigReducer from './siteConfigReducer';
import resourceReducer from './resourceReducer';
import {connectRouter} from 'connected-react-router';
import aisleGenerationSettingReducer from './aisleGenerationSettingReducer';
import configReducer from "./configReducer";

const NO_OPEN_PANEL = -1;
const INITIAL_PROMPT_STATE = {
    show: false, level: null, header: '', message: ''
};
const UNSAVED_CHANGE_ACTION_TYPES = [
    ADD_LED_CONTROLLER,
    DELETE_LED_CONTROLLER,
    SPLIT_LED_CONTROLLER,
    REVERSE_PORT_COLUMN,
    CHANGE_MAC_ADDRESS,
    DUPLICATE_LED_CONTROLLER,
    ADD_SINGLE_AISLE,
    ADD_MULTIPLE_AISLES,
    PASTE_AISLE,
    DELETE_AISLE,
    CHANGE_AISLE_DATA,
    CHANGE_AISLE_TYPE,
    CHANGE_LED_CONTROLLER_TYPE,
    CHANGE_SITE_DEFAULT_AISLE_TYPE,
    CHANGE_COLOR_ORDER,
    ADD_RACK_TO_AISLE,
    DELETE_RACK_FROM_AISLE,
    CHANGE_RACK_DATA,
    CHANGE_AISLE_WORKSPACE
];

const CONFIG_SAVED_ACTION_TYPES = [
    CONFIG_SAVED,
    RESET_SITE_CONFIG,
    IMPORT_SITE_CONFIG_FROM_FILE
];

function expandedController(state = NO_OPEN_PANEL, action) {
    switch (action.type) {
        case TOGGLE_LED_CONTROLLER:
            return state === action.controllerId ? NO_OPEN_PANEL : action.controllerId;
        case EXPAND_LED_CONTROLLER:
            return action.controllerId;
        case ADD_LED_CONTROLLER:
            return action.newControllerId;
        default:
            return state;
    }
}

function clipboard(state = {
    startColumn: '',
    endColumn: '',
    missingColumns: ''
}, action) {
    if (action.type === COPY_AISLE) {
        return {
            ...action.clipboard
        };
    } else {
        return state;
    }
}

function maxPortsAllowed(state = 128) {
    return state;
}

function promptViewReducer(state = INITIAL_PROMPT_STATE, action) {
    switch (action.type) {
        case SHOW_PROMPT:
            return {show: true, ...action.payload};
        case DISMISS_PROMPT:
            return INITIAL_PROMPT_STATE;
        default:
            return state;
    }
}

function validatedReducer(state = false, action) {
    if (action.type === FORM_VALIDATED) {
        return action.validated;
    } else {
        return state;
    }
}

function savedReducer(state = true, action) {
    if (CONFIG_SAVED_ACTION_TYPES.includes(action.type)) {
        return true;
    } else if (UNSAVED_CHANGE_ACTION_TYPES.includes(action.type)) {
        return false;
    }
    return state;
}

function firstInvalidAisleReducer(state = {}, action) {
    if (action.type === SAVE_FIRST_INVALID_AISLE) {
        return {
            controllerId: action.controllerId,
            aisleIndex: action.aisleIndex
        };
    }
    return state;
}

export default (history) => combineReducers({
    siteConfig: siteConfigReducer,
    expandedController,
    clipboard,
    maxPortsAllowed,
    prompts: promptViewReducer,
    validated: validatedReducer,
    firstInvalid: firstInvalidAisleReducer,
    modalStatus: modalReducer,
    resource: resourceReducer,
    router: connectRouter(history),
    saved: savedReducer,
    aisleGenerationSetting: aisleGenerationSettingReducer,
    config: configReducer
});
