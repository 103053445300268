import React, {Component} from 'react';
import {Accordion, Card} from 'react-bootstrap';
import './LEDController.css';
import {connect} from 'react-redux';
import {deleteLEDController, toggleLEDController, openDuplicateControllerModal} from '../../actions/actions';
import ControllerHeader from './ControllerHeader';
import ControllerBody from './ControllerBody';
import deleteControllerIcon from '../Controllers/delete-controller.svg';
import Button from 'react-bootstrap/Button';

export class LEDController extends Component {

    render() {
        return <Card className="controller">
            <Accordion.Toggle as={Card.Header} className='controller-accordion-header' eventKey={this.props.controllerId}
                onClick={this.props.toggleLEDController}>
                <ControllerHeader controllerId={this.props.controllerId}/>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey={this.props.controllerId} mountOnEnter>
                <Card.Body>
                    <ControllerBody controllerId={this.props.controllerId}/>
                </Card.Body>
            </Accordion.Collapse>
            <div className="delete-controls">
                <Button className="delete-controller no-border btn-outline-light mt-1 mb-2 ml-2 mr-2"
                    onClick={this.props.duplicateLEDController}>Duplicate</Button>
                <Button className="delete-controller image-buttons no-border btn-outline-light m-1"
                    onClick={this.props.deleteLEDController}>
                    <img src={deleteControllerIcon} alt="Delete Controller"/>
                </Button>
            </div>
        </Card>;
    }
}

function mapStateToProps(state) {
    return {
        openControllerId: state.expandedController
    };
}

function mapDispatchToProps(dispatch, ownProps) {
    return {
        toggleLEDController: () => dispatch(toggleLEDController(ownProps.controllerId)),
        deleteLEDController: () => dispatch(deleteLEDController(ownProps.controllerId)),
        duplicateLEDController: () => dispatch(openDuplicateControllerModal(ownProps.controllerId))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(LEDController);
