import React, {useContext} from 'react';
import AccordionContext from 'react-bootstrap/AccordionContext';
import {useAccordionToggle} from 'react-bootstrap/AccordionToggle';
import expandIcon from '../Controllers/expand.svg';

export function ContextAwareToggle({children, eventKey, callback}) {
    const currentEventKey = useContext(AccordionContext);
    const decoratedOnClick = useAccordionToggle(eventKey, callback);

    const isCurrentEventKey = currentEventKey === eventKey;

    return (
        <>
            <div className='aisle-toggle'
                onClick={decoratedOnClick}>
                <img src={expandIcon} className={`expand ${isCurrentEventKey ? 'up' : 'down'}`} alt="Expand Aisle"/>
            </div>
            {children}
        </>
    );
}
