import {
    FILTER_AISLE_TYPES,
    FILTER_LED_CONTROLLER_TYPES,
    UPDATE_AISLE_TYPES,
    UPDATE_LED_CONTROLLER_TYPES
} from '../actions/actionTypes';

function updateAisleTypes(state, action) {
    const {aisleTypes} = action;
    return {
        ...state,
        aisleTypes
    };
}

function filterAisleTypes(state, action) {
    const {filteredAisleTypes} = action;
    return {
        ...state,
        filteredAisleTypes
    };
}


function updateLedControllerTypes(state, action) {
    const {ledControllerTypes} = action;
    return {
        ...state,
        ledControllerTypes
    };
}

function filterLedControllerTypes(state, action) {
    const {filteredLedControllerTypes} = action;
    return {
        ...state,
        filteredLedControllerTypes
    };
}

export default (state = {}, action) => {
    switch (action.type) {
        case UPDATE_AISLE_TYPES:
            return updateAisleTypes(state, action);
        case FILTER_AISLE_TYPES:
            return filterAisleTypes(state, action);
        case UPDATE_LED_CONTROLLER_TYPES:
            return updateLedControllerTypes(state, action);
        case FILTER_LED_CONTROLLER_TYPES:
            return filterLedControllerTypes(state, action);
        default:
            return state;
    }
};
