export const APP_DATA_KEYS = {
    TO_BE_DELETE_CONTROLLERS: '[]'
};

class AppData {
    save(key, value) {
        if (value === null || value === undefined) {
            sessionStorage.removeItem(key);
        } else {
            sessionStorage.setItem(key, JSON.stringify(value));
        }
    }

    remove(key) {
        sessionStorage.removeItem(key);
    }

    get(key) {
        const value = sessionStorage.getItem(key);
        try {
            return JSON.parse(value);
        } catch (e) {
            console.log(`error while parsing data for ${key} with value ${value}`);
            return null;
        }
    }

    appendControllerToDelete(controllerName) {
        // controller name refers to the mac address of controller
        const controllersList = this.get(APP_DATA_KEYS.TO_BE_DELETE_CONTROLLERS);
        if (controllersList == null) {
            this.save(APP_DATA_KEYS.TO_BE_DELETE_CONTROLLERS, []);
        }
        controllersList.push(controllerName);
        this.save(APP_DATA_KEYS.TO_BE_DELETE_CONTROLLERS, controllersList);
        console.log("delete controllers list before save to cloud:", this.get(APP_DATA_KEYS.TO_BE_DELETE_CONTROLLERS));
    }
}

const AppDataStore = new AppData();

export default AppDataStore;
