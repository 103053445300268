import {IMPORT_CONFIG_FROM_FILE} from '../actions/actionTypes';

export default (state = {}, action) => {
    switch (action.type) {
        case IMPORT_CONFIG_FROM_FILE:
            return action.config;
        default:
            return state;
    }
};
