import React, {Component} from 'react';
import {Form} from 'react-bootstrap';

export default class AislePortInput extends Component {
    render() {
        return <>
            <Form.Control required className="shadow-none" size="sm" type="number" min="1"
                max={this.props.max} placeholder="1"
                value={this.props.value}
                onBlur={this.props.onBlur}
                onChange={this.props.onChange}/>
            <Form.Control.Feedback type="invalid">Field is required</Form.Control.Feedback>
        </>;
    }
}
