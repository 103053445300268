import React from 'react';
import Form from 'react-bootstrap/Form';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import {connect} from 'react-redux';
import {BUFFER} from "../../constants";
import {
    uploadConfig
} from '../../actions/siteConfigActions';
import * as XLSX from 'xlsx';

function readFileAsTextAsync(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
            resolve(reader.result);
        };
        reader.onerror = reject;
        reader.readAsArrayBuffer(file);
    });
}

class ImportFromFileButton extends React.Component {
    async handleImportFromFile(event) {
        const file = event.target.files[0];
        event.target.value = '';
        if (file) {
            const buffer = await readFileAsTextAsync(file);
            const wb = XLSX.read(buffer, {type: BUFFER})
            const config = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]])
            this.props.uploadConfig(config);
        }
    }

    render() {
        return <OverlayTrigger placement="auto"
            overlay={
                <Tooltip id="tooltip-bottom">
                    Load aisle configs for station <strong>{this.props.stationName}</strong> from local file system
                </Tooltip>
            }
        >
            <Form.Label className="btn btn-primary tall-button no-border shadow-none"
                htmlFor="fileUpload"
                style={{ cursor: 'pointer' }}
            >
                <div className="m-1">{this.props.children}</div>
                <Form.Control
                    id="fileUpload"
                    type="file"
                    accept=".xlsx"
                    onChange={this.handleImportFromFile.bind(this)}
                    style={{ display: 'none' }}
                />
            </Form.Label>
        </OverlayTrigger>;
    }
}

function mapDispatchToProps(dispatch) {
    return {
        uploadConfig: (file) => dispatch(uploadConfig(file)),
    };
}

export default connect(null, mapDispatchToProps)(ImportFromFileButton);
