import React from 'react';
import {connect} from 'react-redux';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import {changeColorOrder} from '../../actions/siteLevelActions';

const COLOR_ORDERS = [
    'RGB', 'RBG', 'GRB', 'GBR', 'BRG', 'BGR'
];

class ColorOrderPicker extends React.Component {
    changeColorOrder(event) {
        const colorOrder = event.target.value;
        this.props.changeColorOrder(colorOrder);
    }

    render() {
        return <InputGroup className="site-level-picker">
            <InputGroup.Prepend>
                <InputGroup.Text>Color Order</InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl as="select"
                aria-describedby="racktype-label"
                className="site-level-picker custom-select shadow-none"
                value={this.props.colorOrder}
                onChange={this.changeColorOrder.bind(this)}>
                {
                    COLOR_ORDERS.map((colorOrder, index) => (
                        <option value={colorOrder} key={index}>{colorOrder}</option>
                    ))
                }
            </FormControl>
        </InputGroup>;
    }
}

function mapStateToProps(state) {
    return {
        colorOrder: state.siteConfig.colorOrder
    };
}

function mapDispatchToProps(dispatch) {
    return {
        changeColorOrder: (colorOrder) => dispatch(changeColorOrder(colorOrder))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ColorOrderPicker);
