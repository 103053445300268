import {
    ADD_LED_CONTROLLER,
    ADD_MULTIPLE_AISLES,
    ADD_SINGLE_AISLE,
    CHANGE_AISLE_DATA,
    CHANGE_MAC_ADDRESS,
    DELETE_AISLE,
    DELETE_LED_CONTROLLER,
    LOAD_STATION,
    PASTE_AISLE,
    DUPLICATE_LED_CONTROLLER,
    CHANGE_AISLE_TYPE,
    SPLIT_LED_CONTROLLER,
    REVERSE_PORT_COLUMN,
    ADD_RACK_TO_AISLE,
    DELETE_RACK_FROM_AISLE,
    CHANGE_RACK_DATA,
    CHANGE_AISLE_WORKSPACE,
    UPDATE_RACK_DIMENSIONS, DELETE_WORKSPACE
} from '../../actions/actionTypes';
import AppDataStore from "../../components/app-data";

const AISLE_NAME_SPLIT_PATTERN = /^([^\d]+)([\d]+)$/;

function getNewAisleMapping(state, action) {
    return state.map((controller, index) => {
        if (index === action.controllerId) {
            return {
                ...controller,
                aisles: controller.aisles.concat(getNextAisles(controller.aisles, action.count, action.rows, action.cols))
            };
        }
        return controller;
    });
}

function getNextAisles(aisles, count, rows, cols) {
    const arr = [];
    let prevAisleName = aisles.length > 0 ? aisles[aisles.length - 1].name : '';
    let prevAislePort = aisles.length > 0 ? aisles[aisles.length - 1].port : 0;
    for (let i = 0; i < count; i++) {
        const currentAisle = {
            name: getNextAisleName(prevAisleName),
            port: ++prevAislePort,
            startColumn: '',
            endColumn: '',
            missingColumns: ''
        };
        if (rows) {
            currentAisle.racks = createNewRacks(rows, cols);
        }
        arr.push(currentAisle);
        prevAisleName = currentAisle.name;
    }
    return arr;
}

function getNextAisleName(currentAisleName, stepping = 1) {
    const splitPatterMatch = currentAisleName.match(AISLE_NAME_SPLIT_PATTERN);
    if (currentAisleName && splitPatterMatch) {
        const [, prefix, aisleNumber] = splitPatterMatch;
        return prefix + (parseInt(aisleNumber, 10) + stepping);
    }
    return currentAisleName;
}

function getAisleDistance(aisleNameA, aisleNameB) {
    const [, prefixA, suffixA] = aisleNameA.match(AISLE_NAME_SPLIT_PATTERN);
    const [, prefixB, suffixB] = aisleNameB.match(AISLE_NAME_SPLIT_PATTERN);
    if (prefixA !== prefixB) {
        return -1; // Or throw
    }
    return parseInt(suffixB, 10) - parseInt(suffixA, 10);
}

function addMultipleAisles(state, action) {
    const {
        controllerId,
        payload: {startAisle, startPort, count, startColumn, endColumn, stepping, aisleType}
    } = action;
    return state.map((controller, index) => {
        console.log(`controllerId=${controllerId}, index=${index}`);
        if (index === controllerId) {
            let aisleName = startAisle;
            const aisles = [].concat(controller.aisles);
            for (let i = 0; i < count; i += 1) {
                const newAisle = {
                    name: aisleName,
                    port: startPort + i * stepping,
                    startColumn,
                    endColumn,
                    missingColumns: '',
                    aisleType
                };
                if (action.rows) {
                    newAisle.racks = createNewRacks(action.rows, action.cols);
                }
                aisles.push(newAisle);
                aisleName = getNextAisleName(aisleName, stepping);
            }
            aisles.sort((a, b) => a.port - b.port);
            return {
                ...controller,
                aisles
            };
        }
        return controller;
    });
}

function duplicateController(state, action) {
    const {sourceControllerId, startAisle, macAddress = ''} = action.payload;
    const sourceController = state[sourceControllerId];
    const sourceAisles = sourceController.aisles;
    const sourceStartAisle = sourceAisles[0];
    const newController = {macAddress, aisles: []};
    for (let i = 0; i < sourceAisles.length; i += 1) {
        const sourceAisle = sourceAisles[i];
        const distanceToStartAisle = getAisleDistance(sourceStartAisle.name, sourceAisle.name);
        newController.aisles.push(Object.assign({}, sourceAisle, {
            name: getNextAisleName(startAisle, distanceToStartAisle)
        }));
    }
    return [...state, newController];
}

function splitController(state, action) {
    const {sourceControllerId, splitIndex} = action.payload;
    const sourceController = state[sourceControllerId];
    const sourceAisles = sourceController.aisles;
    const newController = {macAddress: '', aisles: sourceAisles.slice(splitIndex)};
    for (let i = 0; i < newController.aisles.length; i += 1) {
        newController.aisles[i].port = i + 1;
    }
    const newState = [...state];
    newState[sourceControllerId].aisles = sourceAisles.slice(0, splitIndex);
    newState.splice(sourceControllerId + 1, 0, newController);
    return newState;
}

function reversePortColumn(state, action) {
    const {controllerId} = action.payload;
    const aisles = state[controllerId].aisles;
    const reversedAisles = [];
    const length = aisles.length;
    for (let i = 0; i < length; i += 1) {
        reversedAisles.push({
            name: aisles[i].name,
            port: aisles[length - 1 - i].port,
            startColumn: aisles[i].startColumn,
            endColumn: aisles[i].endColumn,
            missingColumns: aisles[i].missingColumns
        });
    }
    const newState = [...state];
    newState[controllerId].aisles = reversedAisles;
    return newState;
}

function updateAisle(state, targetControllerId, targetAisleIndex, newAisle) {
    return state.map((controller, controllerId) => {
        if (controllerId === targetControllerId) {
            return {
                ...controller,
                aisles: controller.aisles.map((aisle, aisleIndex) => {
                    if (aisleIndex === targetAisleIndex) {
                        return Object.assign({}, aisle, newAisle);
                    }
                    return aisle;
                })
            };
        }
        return controller;
    });
}

function createNewRacks(rowCount, colCount) {
    return [Array.from({length: rowCount}, () => new Array(colCount).fill(''))];
}

function updateWithNewRackToAisle(state, action) {
    return state.map((controller, controllerId) => {
        if (controllerId === action.controllerId) {
            return {
                ...controller,
                aisles: controller.aisles.map((aisle, aisleIndex) => {
                    if (aisleIndex === action.aisleIndex) {
                        return {
                            ...aisle,
                            racks: aisle.racks.concat(createNewRacks(action.rows, action.cols))
                        };
                    }
                    return aisle;
                })
            };
        }
        return controller;
    });
}

function updateWithDeleteRackFromAisle(state, action) {
    return state.map((controller, controllerId) => {
        if (controllerId === action.controllerId) {
            return {
                ...controller,
                aisles: controller.aisles.map((aisle, aisleIndex) => {
                    if (aisleIndex === action.aisleIndex) {
                        return {
                            ...aisle,
                            racks: aisle.racks.filter((rack, rackIndex) => rackIndex !== action.rackIndex)
                        };
                    }
                    return aisle;
                })
            };
        }
        return controller;
    });
}

function updateRackForAisle(state, action) {
    return state.map((controller, controllerId) => {
        if (controllerId === action.controllerId) {
            return {
                ...controller,
                aisles: controller.aisles.map((aisle, aisleIndex) => {
                    if (aisleIndex === action.aisleIndex) {
                        return {
                            ...aisle,
                            racks: aisle.racks.map((rack, rackIndex) => {
                                if (rackIndex === action.rackIndex) {
                                    return action.data;
                                }
                                return rack;
                            })
                        };
                    }
                    return aisle;
                })
            };
        }
        return controller;
    });
}

function resizeRack(oldRack, newRowCount, newColCount) {
    const newRack = [];
    for (let i = 0; i < newRowCount; i++) {
        const newRow = [];
        for (let j = 0; j < newColCount; j++) {
            if (oldRack[i] && oldRack[i][j]) {
                newRow.push(oldRack[i][j]);
            } else {
                newRow.push('');
            }
        }
        newRack.push(newRow);
    }
    return newRack;
}

function updateAllRackDimensions(state, action) {
    return state.map((controller) => ({
        ...controller,
        aisles: controller.aisles.map((aisle, aisleIndex) => ({
            ...aisle,
            racks: aisle.racks.map((rack) => resizeRack(rack, action.rows, action.cols))
        }))
    }));
}

function getNewMappingWithUnselectWorkspace(state, workSpaceName) {
    return state.map((controller) => ({
        ...controller,
        aisles: controller.aisles.map((aisle, aisleIndex) => {
            if (aisle.workspace && aisle.workspace.value === workSpaceName) {
                return {
                    ...aisle,
                    workspace: null
                };
            }
            return aisle;
        })
    }));
}

export default (state = [], action) => {
    switch (action.type) {
        case ADD_LED_CONTROLLER:
            return [...state, {
                macAddress: '',
                aisles: []
            }];
        case DELETE_WORKSPACE:
            return getNewMappingWithUnselectWorkspace(state, action.workSpaceName);
        case LOAD_STATION:
            return action.newState;
        case ADD_SINGLE_AISLE:
            action.count = 1;
            return getNewAisleMapping(state, action);
        case ADD_MULTIPLE_AISLES:
            return addMultipleAisles(state, action);
        case DELETE_LED_CONTROLLER:
            const toBeDeleteController = state[action.controllerId].macAddress;
            // store all controllers that are deleted in session storage
            AppDataStore.appendControllerToDelete(toBeDeleteController);
            return state.filter((controller, index) => index !== action.controllerId);
        case PASTE_AISLE:
            return state.map((controller, controllerId) => {
                if (controllerId === action.controllerId) {
                    return {
                        ...controller,
                        aisles: controller.aisles.map((aisle, aisleIndex) => {
                            if (aisleIndex === action.aisleIndex) {
                                return {
                                    ...aisle,
                                    ...action.clipboard
                                };
                            }
                            return aisle;
                        })
                    };
                }
                return controller;
            }
            );
        case CHANGE_AISLE_DATA:
            return state.map((controller, controllerId) => {
                if (controllerId === action.controllerId) {
                    return {
                        ...controller,
                        aisles: controller.aisles.map((aisle, aisleIndex) => {
                            if (aisleIndex === action.aisleIndex) {
                                return {...aisle, ...action.data};
                            }
                            return aisle;
                        })
                    };
                }
                return controller;
            }
            );
        case DELETE_AISLE:
            return state.map((controller, controllerId) => {
                if (controllerId === action.controllerId) {
                    return {
                        ...controller,
                        aisles: controller.aisles.filter((aisle, index) => index !== action.aisleIndex)
                    };
                }
                return controller;
            });
        case CHANGE_MAC_ADDRESS:
            return state.map((controller, index) => {
                if (index === action.controllerId) {
                    return {
                        ...controller,
                        macAddress: action.macAddress
                    };
                }
                return controller;
            });
        case DUPLICATE_LED_CONTROLLER:
            return duplicateController(state, action);
        case CHANGE_AISLE_TYPE:
            return updateAisle(state, action.controllerId, action.aisleIndex, {aisleType: action.aisleType});
        case CHANGE_AISLE_WORKSPACE:
            return updateAisle(state, action.controllerId, action.aisleIndex, {workspace: action.workspace});
        case SPLIT_LED_CONTROLLER:
            return splitController(state, action);
        case REVERSE_PORT_COLUMN:
            return reversePortColumn(state, action);
        case ADD_RACK_TO_AISLE:
            return updateWithNewRackToAisle(state, action);
        case DELETE_RACK_FROM_AISLE:
            return updateWithDeleteRackFromAisle(state, action);
        case CHANGE_RACK_DATA:
            return updateRackForAisle(state, action);
        case UPDATE_RACK_DIMENSIONS:
            return updateAllRackDimensions(state, action);
        default:
            return state;
    }
};
