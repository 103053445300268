import React, {Component} from 'react';
import InputGroup from 'react-bootstrap/InputGroup';
import {Button} from 'react-bootstrap';
import {connect} from 'react-redux';
import {openChangeSiteModal} from '../../actions/actions';

export class StationNameField extends Component {
    render() {
        return <InputGroup className="station-name">
            <InputGroup.Prepend>
                <InputGroup.Text>{this.props.stationName || ''}</InputGroup.Text>
            </InputGroup.Prepend>
            <InputGroup.Append>
                <Button variant="warning" onClick={this.props.openChangeSiteModal}>Change</Button>
            </InputGroup.Append>
        </InputGroup>;
    }
}

function mapStateToProps(state) {
    return {
        stationName: state.siteConfig.stationName
    };
}

function mapDispatchToProps(dispatch) {
    return {
        openChangeSiteModal: () => dispatch(openChangeSiteModal())
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(StationNameField);
