import React from 'react';
import {Container, Form, OverlayTrigger, Row} from 'react-bootstrap';
import Controllers from '../Controllers/Controllers';
import './DataBox.css';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import {connect} from 'react-redux';
import {addLEDController} from '../../actions/actions';
import {saveToCloud, deploy, loadFromCloud} from '../../actions/siteConfigActions';

import Tooltip from 'react-bootstrap/Tooltip';
import Prompt from './Prompts';
import StationNameField from '../StationNameField/StationNameField';
import ImportFromFileButton from './ImportFromFileButton';
import ColorOrderPicker from './ColorOrderPicker';
import AisleTypePicker from './AisleTypePicker';
import LedControllerTypePicker from './LedControllerTypePicker';
import ExportToFileButton from './ExportToFileButton';

class DataBox extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.confirmForm = this.confirmForm.bind(this);
    }

    confirmForm(event) {
        event.preventDefault();
        this.props.saveSiteConfigToCloud();
    }

    deploySiteConfig(event) {
        event.preventDefault();
        this.props.deploySiteConfig();
    }

    static handleOnSubmit(event) {
        event.preventDefault();
    }

    render() {
        return <Container className="databox">
            <Prompt/>
            <Form onSubmit={DataBox.handleOnSubmit} validated={this.props.validated}>
                <Row>
                    <Col md={12} sm={12} xs={12}><Controllers/></Col>
                </Row>
                <Row className="add-controller-row">
                    <Col>
                        {this.getOverlayAddControllerButton()}
                    </Col>
                </Row>
                <Row className="confirm-buttons">
                    <Col md={2} sm={12} xs={12}>
                        <StationNameField/>
                    </Col>
                    <Col md={4} sm={12} xs={12}>
                        <LedControllerTypePicker/>
                    </Col>
                    <Col md={4} sm={12} xs={12}>
                        <AisleTypePicker/>
                    </Col>
                    <Col md={2} sm={12} xs={12}>
                        <ColorOrderPicker/>
                    </Col>
                </Row>
                <Row>
                    <Col md={1}/>
                    <Col md={2} sm={12} xs={12} className="gap-below">
                        <ExportToFileButton/>
                    </Col>
                    <Col md={2} sm={12} xs={12} className="gap-below">
                        <ImportFromFileButton>Import Config</ImportFromFileButton>
                    </Col>
                    <Col md={2} sm={12} xs={12} className="gap-below">
                        <OverlayTrigger
                            placement="auto"
                            overlay={
                                <Tooltip id={'tooltip-bottom'}>
                                    Load site config for station <strong>{this.props.stationName}</strong> from cloud
                                </Tooltip>
                            }>
                            <Button className="add-controller-btn no-border shadow-none" id="load"
                                onClick={this.props.loadStation}>Load from Cloud</Button>
                        </OverlayTrigger>
                    </Col>
                    <Col md={2} sm={12} xs={12} className="gap-below">
                        <OverlayTrigger
                            placement="auto"
                            overlay={
                                <Tooltip id={'tooltip-bottom'}>
                                    Save site config for station <strong>{this.props.stationName}</strong> to cloud
                                </Tooltip>
                            }>
                            <Button className="add-controller-btn no-border shadow-none" id="confirm"
                                onClick={this.confirmForm}>Save to Cloud</Button>
                        </OverlayTrigger>
                    </Col>
                    <Col md={2} sm={12} xs={12} className="gap-below">
                        <OverlayTrigger
                            placement="auto"
                            overlay={
                                <Tooltip id={'tooltip-bottom'}>
                                    Deploy <strong>current</strong> site config for
                                    station <strong>{this.props.stationName}</strong> to AWS IoT
                                </Tooltip>
                            }>
                            <Button className="tall-button no-border shadow-none" id="deploy"
                                onClick={this.deploySiteConfig.bind(this)}>Deploy</Button>
                        </OverlayTrigger>
                    </Col>
                </Row>
            </Form>
        </Container>;
    }

    getOverlayAddControllerButton() {
        const component = (
            <Button className="add-controller-btn no-border shadow-none" id="add-controller"
                style={this.props.disabledAddLedButton ? {pointerEvents: 'none'} : {}}
                onClick={this.props.addLEDController}
                disabled={this.props.disabledAddLedButton}>
                Add LED Controller</Button>
        );

        if (this.props.disabledAddLedButton) {
            return (
                <OverlayTrigger
                    placement="auto"
                    overlay={
                        <Tooltip id={'tooltip-bottom'}>
                            Select Default Rack Type before adding LED Controller
                        </Tooltip>
                    }>
                    <div style={{cursor: 'not-allowed'}}>
                        {component}
                    </div>
                </OverlayTrigger>
            );
        }
        return component;
    }
}

function mapStateToProps(state) {
    return {
        stationName: state.siteConfig.stationName,
        siteConfig: state.siteConfig,
        validated: state.validated,
        disabledAddLedButton: state.aisleGenerationSetting.shouldEnterBinLabel && !state.siteConfig.aisleType
    };
}

function mapDispatchToProps(dispatch) {
    return {
        addLEDController: () => dispatch(addLEDController()),
        saveSiteConfigToCloud: () => dispatch(saveToCloud()),
        loadStation: () => dispatch(loadFromCloud()),
        deploySiteConfig: () => dispatch(deploy())
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(DataBox);
