import React, {Component} from 'react';
import {OverlayTrigger, Table} from 'react-bootstrap';
import {connect} from 'react-redux';
import Tooltip from 'react-bootstrap/Tooltip';
import AisleRow from './AisleRow';
import Button from 'react-bootstrap/Button';
import {reversePortColumn} from '../../actions/actions';
import reverse from '../Controllers/reverse.svg';
import {forwardAfterPrevent} from './AisleStateManipulations';

export class TableHeadWithToolTip extends Component {
    render() {
        return <th className="aisle-normal">
            <OverlayTrigger
                placement={this.props.placement ? this.props.placement : 'left'}
                overlay={
                    <Tooltip>
                        {this.props.prompt}
                    </Tooltip>
                }>
                <span>{this.props.label}</span>
            </OverlayTrigger>
            {this.props.isRequired ? <span className="required-field">&nbsp;*</span> : ''}
            {this.props.isPortColumn
                ? <OverlayTrigger
                    placement="auto"
                    overlay={
                        <Tooltip id={'tooltip-bottom'}>
                            Reverse ports
                        </Tooltip>
                    }>
                    <Button className="hide-in-mobile image-buttons no-border btn-outline-light"
                        onClick={(event) => forwardAfterPrevent(event, this.props.onReverse)}>
                        <img src={reverse} alt="Reverse ports"/>
                    </Button>
                </OverlayTrigger>
                : ''}
        </th>;
    }
}

class AisleTable extends Component {

    static forwardAfterPrevent(event, callBack) {
        event.preventDefault();
        callBack();
    }

    renderHeader() {
        const {aisleGenerationSetting} = this.props;
        this.allowAisleTypeOverride = aisleGenerationSetting.allowAisleTypeOverride;
        this.hideColumnInputs = aisleGenerationSetting.hideColumnInputs;

        return (
            <tr>
                <TableHeadWithToolTip label={'Name'} isRequired={true} prompt={'Aisle\'s Name'} placement={'auto'} isPortColumn={false}/>
                <TableHeadWithToolTip label={'Port'} isRequired={true}
                    prompt={'Controller port number the aisle is connected to'} placement={'auto'}
                    isPortColumn={true}
                    onReverse={this.props.onReverse}
                />
                { !this.hideColumnInputs && this.renderColumnInputs() }
                { this.allowAisleTypeOverride && <TableHeadWithToolTip label={'Aisle Type'} isRequired={false} isPortColumn={false}/> }
                <th className="action-header"><span className="hide-in-mobile"> Actions</span></th>
            </tr>
        );
    }

    renderColumnInputs() {
        return (
            <>
            <TableHeadWithToolTip label={'Start Column'} isRequired={true}
                prompt={'Column letter of the bin where the controller is connected'}
                placement={'right'} isPortColumn={false}
            />
            <TableHeadWithToolTip label={'End Column'} isRequired={true}
                prompt={'Column letter of the bin where lights end'}
                isPortColumn={false}
            />
            <TableHeadWithToolTip label={'Missing Columns'} isRequired={false}
                prompt={
                                      <>
                                      <div>Column letter of missing bins (empty if none)</div>
                                      <div>Column I and F are always ignored</div>
                                      </>}
                isPortColumn={false}
            />
            </>);
    }

    renderAisles() {
        return (
            this.props.aisles.map((aisleRow, index) =>
                <AisleRow
                    controllerId={this.props.controllerId}
                    key={index}
                    aisleIndex={index}
                    allowAisleTypeOverride={this.allowAisleTypeOverride}
                    hideColumnInputs={this.hideColumnInputs}
                />)
        );
    }

    render() {
        return <Table responsive striped className="aisle-table" size="sm">
            <thead className="aisle-table-header">
                {this.renderHeader() }
            </thead>
            <tbody>
                { this.renderAisles() }
            </tbody>
        </Table>;
    }
}

function mapStateToProps(state, ownProps) {
    const currentController = state.siteConfig.controllers[ownProps.controllerId];
    return {
        aisles: currentController.aisles,
        aisleGenerationSetting: state.aisleGenerationSetting
    };
}

function mapDispatchToProps(dispatch, ownProps) {
    return {
        // Reverse all port columns in current controller
        onReverse: () => dispatch(reversePortColumn(ownProps.controllerId))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(AisleTable);
