import React, {Component} from 'react';
import {Form} from 'react-bootstrap';
import {AISLE_MISSING_COL_PATTERN} from '../../constants';
import copy from '../Controllers/copy.svg';
import paste from '../Controllers/paste.svg';
import split from '../Controllers/split.svg';
import {connect} from 'react-redux';
import {changeAisleData, copyAisle, deleteAisle, pasteAisle, splitControllerAisles} from '../../actions/actions';
import AisleTypeOverridePicker from '../LEDController/AisleTypeOverridePicker';
import {getAppConfig} from '../../configs/app-config';
import './Aisle.css';
import {
    AISLE_END_COL,
    AISLE_MISSING_COL,
    AISLE_NAME,
    AISLE_PORT,
    AISLE_START_COL,
    pushState
} from './AisleStateManipulations';
import remove from '../Controllers/remove.svg';
import AisleNameInput from './AisleNameInput';
import AislePortInput from './AislePortInput';
import ButtonWithOverlayTrigger from './ButtonWithOverlayTrigger';

export class AisleRow extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            ...props
        };
        this.aisleValueChange = this.aisleValueChange.bind(this);
        this.aisleColumnValueChange = this.aisleColumnValueChange.bind(this);
    }

    aisleValueChange(e, fieldName) {
        const newState = {};
        newState[fieldName] = e.target.value.toUpperCase();
        this.setState(newState);
    }

    aisleColumnValueChange(e, fieldName) {
        const newState = {};
        const text = e.target.value.toUpperCase();
        if (AisleRow.isValidColumnInputChange(text)) {
            newState[fieldName] = text;
            this.setState(newState);
        }
    }

    static isValidColumnInputChange(text) {
        // === 0 because user should be allowed to remove character
        return text.length === 0 || AisleRow.isValidColumnInput(text);
    }

    static isValidColumnInput(text) {
        return (text.length === 1 && text[0] >= 'A' && text[0] <= 'Z');
    }

    invert() {
        this.setState({
            startColumn: this.state.endColumn,
            endColumn: this.state.startColumn
        }, () => pushState(this.state, this.props));
    }

    render() {
        const {opConfig} = getAppConfig();
        return <tr className="aisle">
            <td className='aisle-normal'>
                <AisleNameInput opConfig={opConfig} value={this.state.name}
                    aisleGenerationSetting={this.props.aisleGenerationSetting}
                    onBlur={(e) => pushState(this.state, this.props)}
                    onChange={(e) => this.aisleValueChange(e, AISLE_NAME)}/>
            </td>
            <td className='aisle-normal'>
                <AislePortInput max={this.props.maxPortsAllowed} value={this.state.port}
                    onBlur={(e) => pushState(this.state, this.props)}
                    onChange={(e) => this.aisleValueChange(e, AISLE_PORT)}/>
            </td>
            {
                !this.props.hideColumnInputs
                    ? <>
                        <td className='aisle-normal'>
                            <Form.Control required className="shadow-none caps-input" size="sm" type="text"
                                placeholder="A" value={this.state.startColumn}
                                onBlur={(e) => pushState(this.state, this.props)}
                                onChange={(e) => this.aisleColumnValueChange(e, AISLE_START_COL)}/>
                            <Form.Control.Feedback type="invalid">Field is required</Form.Control.Feedback>
                        </td>
                        <td className='aisle-normal'>
                            <Form.Control required className="shadow-none caps-input" size="sm" type="text"
                                placeholder="G" value={this.state.endColumn}
                                onBlur={(e) => pushState(this.state, this.props)}
                                onChange={(e) => this.aisleColumnValueChange(e, AISLE_END_COL)}/>
                            <Form.Control.Feedback type="invalid">Field is required</Form.Control.Feedback>
                        </td>
                        <td className="aisle-missing-col">
                            <Form.Control className="shadow-none caps-input" size="sm" type="text"
                                placeholder="C,D" value={this.state.missingColumns}
                                pattern={AISLE_MISSING_COL_PATTERN}
                                onBlur={(e) => pushState(this.state, this.props)}
                                onChange={(e) => this.aisleValueChange(e, AISLE_MISSING_COL)}/>
                            <Form.Control.Feedback type="invalid">
                                Field must have comma separated column names only
                            </Form.Control.Feedback>
                        </td>
                    </>
                    : undefined
            }
            {
                this.props.allowAisleTypeOverride
                    ? <td className='aisle-normal'>
                        <AisleTypeOverridePicker selected={this.props.aisleType} controllerId={this.props.controllerId}
                            aisleIndex={this.props.aisleIndex}/>
                    </td>
                    : undefined
            }
            <td className='aisle-actions'>
                <ButtonWithOverlayTrigger tooltipPrompt='Copy'
                    onClick={this.props.onCopy} additionalClasses='hide-in-mobile image-buttons'>
                    <img src={copy} alt={'Copy Aisle'}/>
                </ButtonWithOverlayTrigger>
                <span className="hide-in-mobile"> &nbsp;&nbsp;&nbsp;</span>
                <ButtonWithOverlayTrigger tooltipPrompt='Paste'
                    onClick={this.props.onPaste} additionalClasses='hide-in-mobile image-buttons'>
                    <img src={paste} alt={'Paste Aisle'}/>
                </ButtonWithOverlayTrigger>
                <span className="hide-in-mobile"> &nbsp;&nbsp;&nbsp;</span>
                <ButtonWithOverlayTrigger tooltipPrompt='Split Controller Aisles'
                    onClick={this.props.onSplit} additionalClasses='hide-in-mobile image-buttons'>
                    <img src={split} alt={'Split Aisles'}/>
                </ButtonWithOverlayTrigger>
                <span className="hide-in-mobile"> &nbsp;&nbsp;&nbsp;</span>
                <ButtonWithOverlayTrigger tooltipPrompt='Remove Aisle'
                    onClick={this.props.onDelete} additionalClasses='hide-in-mobile image-buttons'>
                    <img src={remove} alt={'Delete Aisle'}/>
                </ButtonWithOverlayTrigger>
                <span className="hide-in-mobile"> &nbsp;&nbsp;&nbsp;</span>

                {
                    !this.props.hideColumnInputs
                        ? <ButtonWithOverlayTrigger tooltipPrompt='Exchange start and end column'
                            onClick={this.invert.bind(this)}>
                            Invert
                        </ButtonWithOverlayTrigger>
                        : undefined
                }
            </td>
        </tr>;
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            ...nextProps
        });
    }
}

function mapStateToProps(state, ownProps) {
    const currentAisle = state.siteConfig.controllers[ownProps.controllerId].aisles[ownProps.aisleIndex];
    return {
        maxPortsAllowed: state.maxPortsAllowed,
        name: currentAisle.name,
        port: currentAisle.port,
        startColumn: currentAisle.startColumn,
        endColumn: currentAisle.endColumn,
        missingColumns: currentAisle.missingColumns,
        aisleType: currentAisle.aisleType ? currentAisle.aisleType.s3Key : undefined,
        aisleGenerationSetting: state.aisleGenerationSetting
    };
}

function mapDispatchToProps(dispatch, ownProps) {
    return {
        onCopy: () => dispatch(copyAisle(ownProps.controllerId, ownProps.aisleIndex)),
        onPaste: () => dispatch(pasteAisle(ownProps.controllerId, ownProps.aisleIndex)),
        onDelete: () => dispatch(deleteAisle(ownProps.controllerId, ownProps.aisleIndex)),
        onValueChange: (data) => dispatch(changeAisleData(ownProps.controllerId, ownProps.aisleIndex, data)),
        onSplit: () => dispatch(splitControllerAisles(ownProps.controllerId, ownProps.aisleIndex))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(AisleRow);
