import React from 'react';
import {connect} from 'react-redux';
import Alert from 'react-bootstrap/Alert';
import { dismissPrompt } from '../../actions/notificationActions';
import { PROMPT_LEVELS } from '../../constants';

class Prompt extends React.Component {
    getVariantFromLevel() {
        const {level} = this.props;
        switch (level) {
            case PROMPT_LEVELS.INFO:
                return 'success';
            case PROMPT_LEVELS.WARN:
                return 'warning';
            case PROMPT_LEVELS.ERROR:
                return 'danger';
            default:
                return undefined;
        }
    }

    generateHeader() {
        return this.props.header != null && this.props.header !== ''
            ? <Alert.Heading>{this.props.header}</Alert.Heading>
            : <></>;
    }

    generateMessage() {
        return this.props.message != null && this.props.message !== ''
            ? <p>{this.props.message}</p>
            : <></>;
    }

    render() {
        return <Alert dismissible
            show={this.props.show}
            variant={this.getVariantFromLevel(this.props.level)}
            onClose={this.props.close.bind(this)}
        >
            {this.generateHeader()}
            {this.generateMessage()}
        </Alert>;
    }
}

function mapStateToProps(state) {
    return {
        ...state.prompts
    };
}

function mapDispatchToProps(dispatch) {
    return {
        close: () => dispatch(dismissPrompt())
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Prompt);
