import React from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import {connect} from 'react-redux';
import { closeChangeSiteModal } from '../../actions/actions';
import {push} from 'connected-react-router';
import {STATION_NAME_PATTERN} from '../../constants';
import { isValidStationName } from '../../validations/validations';

class ChangeSiteModal extends React.Component {
    state = {}

    isStateValid() {
        const {siteId} = this.state;
        return isValidStationName(siteId);
    }

    handleEnterModal() {
        this.setState({siteId: this.props.currentSiteId});
    }

    handleSiteIdChange(event) {
        const siteId = event.target.value.toUpperCase();
        this.setState({siteId});
    }

    handleConfirm() {
        this.setState({validated: true});
        if (this.isStateValid()) {
            this.props.confirm(this.state.siteId);
            this.handleClose();
        }
    }

    handleClose() {
        this.setState({validated: false});
        this.props.close();
    }

    render() {
        // Set animation to false to avoid race condition
        // between react bootstrap modals.
        // Having 2 modals with animations running at the same time can
        // reverse vertical scroll behaviors:
        // 1. screen can scroll when a modal is showing
        // 2. screen becomes locked when all modals are hiden
        return <Modal show={this.props.show}
            animation={false}
            onHide={this.props.close}
            onShow={this.handleEnterModal.bind(this)}
        >
            <Form noValidate
                validated={this.state.validated}
                onSubmit={(event) => {
                    event.preventDefault();
                    this.handleConfirm();
                }}
            >
                <Modal.Header closeButton={this.props.showCancelButton}>
                    <Modal.Title>
                        {this.props.title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Control type="text"
                        required
                        pattern={STATION_NAME_PATTERN}
                        value={this.state.siteId || ''}
                        onChange={this.handleSiteIdChange.bind(this)}/>
                    <Form.Control.Feedback type="invalid">Site ID must have a valid format. Example: DSE5</Form.Control.Feedback>
                </Modal.Body>
                <Modal.Footer>
                    {this.props.showCancelButton
                        ? <Button variant="danger" onClick={this.handleClose.bind(this)}>
                            Cancel
                        </Button>
                        : null
                    }
                    <Button variant="success" type="submit">
                        Confirm
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>;
    }
}

function mapStateToProps(state, ownProps) {
    const currentSiteId = state.siteConfig.stationName;
    const isSiteIdAbsent = ownProps.urlSiteId == null || ownProps.urlSiteId === '';
    return {
        title: isSiteIdAbsent ? 'Set Site' : 'Change Site',
        currentSiteId,
        showCancelButton: !isSiteIdAbsent,
        show: isSiteIdAbsent || state.modalStatus.changeSiteModal.show
    };
}

function mapDispatchToProps(dispatch) {
    return {
        close: () => dispatch(closeChangeSiteModal()),
        confirm: (siteId) => dispatch(push(`/site/${siteId}`))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangeSiteModal);
