export const AISLE_NAME = 'name';
export const AISLE_PORT = 'port';
export const AISLE_START_COL = 'startColumn';
export const AISLE_END_COL = 'endColumn';
export const AISLE_MISSING_COL = 'missingColumns';
export const AISLE_CONNECTION_SIDE = 'connectionSide';
export const AISLE_CONNECTION_SIDE_TYPES = {
    LEFT: 'LEFT',
    RIGHT: 'RIGHT'
};

function hasValuesChanged(state, props) {
    const fieldNames = ['name', 'port', 'startColumn', 'endColumn', 'missingColumns', AISLE_CONNECTION_SIDE];
    for (let i = 0; i < fieldNames.length; i++) {
        if (state[fieldNames[i]] !== props[fieldNames[i]]) {
            return true;
        }
    }
    return false;
}

export function pushState(state, props) {
    if (hasValuesChanged(state, props)) {
        const newAisleData = {
            name: state.name,
            port: state.port,
            startColumn: state.startColumn,
            endColumn: state.endColumn,
            missingColumns: state.missingColumns,
            racks: state.racks,
            connectionSide: state.connectionSide,
        };
        props.onValueChange(newAisleData);
    }
}

export function forwardAfterPrevent(event, callBack) {
    event.preventDefault();
    callBack();
}
