import React, {Component} from 'react';
import {OverlayTrigger} from 'react-bootstrap';
import Tooltip from 'react-bootstrap/Tooltip';
import Button from 'react-bootstrap/Button';
import {forwardAfterPrevent} from './AisleStateManipulations';

export default class ButtonWithOverlayTrigger extends Component {
    render() {
        let classes = ' no-border btn-outline-light';
        if (this.props.additionalClasses) {
            classes += this.props.additionalClasses + classes;
        }
        return <OverlayTrigger
            placement="auto"
            overlay={
                <Tooltip id={'tooltip-bottom'}>
                    {this.props.tooltipPrompt}
                </Tooltip>
            }>
            <Button className={classes}
                onClick={(event) => forwardAfterPrevent(event, this.props.onClick)}>
                {this.props.children}
            </Button>
        </OverlayTrigger>;
    }
}
