import {CHANGE_AISLE_GENERATION_SETTING, UPDATE_AISLE_GENERATION_DIMENSIONS} from '../actions/actionTypes';

export default (state = '', action) => {
    switch (action.type) {
        case CHANGE_AISLE_GENERATION_SETTING:
            return action.setting;
        case UPDATE_AISLE_GENERATION_DIMENSIONS:
            return {...state, rowsInRack: action.rows, colsInRack: action.cols};
        default:
            return state;
    }
};
