import React, {Component} from 'react';
import {Form} from 'react-bootstrap';
import {getAisleNameAttributes} from '../../configs/app-config';

export default class AisleNameInput extends Component {
    render() {
        const aisleNameAttributes = getAisleNameAttributes(this.props.aisleGenerationSetting);
        return <>
            <Form.Control required className="shadow-none caps-input" size="sm" type="text"
                placeholder={aisleNameAttributes.aisleNamePlaceholder}
                pattern={aisleNameAttributes.aisleNamePattern}
                value={this.props.value}
                onBlur={this.props.onBlur}
                onChange={this.props.onChange}/>
            <Form.Control.Feedback type="invalid"> Aisle Name should be of the form {aisleNameAttributes.aisleNamePlaceholder}</Form.Control.Feedback>
        </>;
    }
}
