import React from 'react';
import {connect} from 'react-redux';
import FormControl from 'react-bootstrap/FormControl';
import {changeAisleType} from '../../actions/actions';

class AisleTypeOverridePicker extends React.Component {
    generateOptions() {
        const options = [<option value={undefined} key="default">Default</option>];
        if (this.props.available) {
            for (const key of Object.keys(this.props.available)) {
                const {s3Key, configName} = this.props.available[key];
                options.push(<option value={s3Key} key={s3Key}>{configName}</option>);
            }
        }
        return options;
    }

    handleChange(event) {
        const s3Key = event.target.value;
        const newAisleType = this.props.available[s3Key] ? this.props.available[s3Key] : null;
        this.props.changeAisleType(newAisleType);
    }

    render() {
        return <FormControl as="select"
            size="sm"
            className="shadow-none"
            value={this.props.selected}
            onChange={this.handleChange.bind(this)}>
            {this.generateOptions()}
        </FormControl>;
    }
}

function mapStateToProps(state) {
    return {
        available: state.resource.filteredAisleTypes,
    };
}

function mapDispatchToProps(dispatch, ownProps) {
    return {
        changeAisleType: (aisleType) => dispatch(changeAisleType(ownProps.controllerId, ownProps.aisleIndex, aisleType))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(AisleTypeOverridePicker);
