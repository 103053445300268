import {CREATE_WORKSPACE, DELETE_WORKSPACE} from '../../actions/actionTypes';

export default function workspacesReducer(state = [], action) {
    if (action.type === CREATE_WORKSPACE) {
        return [...state, action.newOption];
    } else if (action.type === DELETE_WORKSPACE) {
        return state.filter((workspaceOption, index) => workspaceOption.value !== action.workSpaceName);
    } else {
        return state;
    }
}
