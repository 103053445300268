import {
    FORM_VALIDATED,
    OPEN_LOADING_MODAL,
    CLOSE_LOADING_MODAL,
    SHOW_PROMPT,
    DISMISS_PROMPT
} from './actionTypes';

export function showPrompt({level, header, message}) {
    return (dispatch) => {
        dispatch({
            type: SHOW_PROMPT,
            payload: {
                level, header, message
            }
        });
    };
}

export function dismissPrompt() {
    return {type: DISMISS_PROMPT};
}

export function formValidated(validated) {
    return {type: FORM_VALIDATED, validated};
}

export function openLoadingModal(message) {
    return {type: OPEN_LOADING_MODAL, message};
}

export function closeLoadingModal() {
    return {type: CLOSE_LOADING_MODAL};
}
