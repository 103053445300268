import React from 'react';
import Modal from 'react-bootstrap/Modal';
import {closeConfirmActionModal} from '../../actions/actions';
import {connect} from 'react-redux';
import Button from 'react-bootstrap/Button';

class ConfirmActionModal extends React.Component {
    render() {
        return <Modal show={this.props.show} size="sm" animation={false} onHide={this.props.close} centered>
            <Modal.Header closeButton/>
            <Modal.Body>
                <h5>{this.props.prompt}</h5>
                <Button variant='success' style={{float: 'left'}} onClick={this.props.close}>No</Button>
                <Button variant='danger' style={{float: 'right'}}
                    onClick={() => this.props.dispatchConfirmAction(this.props.onConfirmAction)}>Yes</Button>
            </Modal.Body>
            <Modal.Footer/>
        </Modal>;
    }
}

function mapStateToProps(state) {
    return {
        show: state.modalStatus.confirmActionModal.show,
        prompt: state.modalStatus.confirmActionModal.prompt,
        onConfirmAction: state.modalStatus.confirmActionModal.onConfirmAction
    };
}

function mapDispatchToProps(dispatch) {
    return {
        close: () => dispatch(closeConfirmActionModal()),
        dispatchConfirmAction: (onConfirmAction) => dispatch(onConfirmAction())
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmActionModal);
