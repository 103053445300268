import WorkSpacePicker from '../DataBox/WorkSpacePicker';
import React, {Component} from 'react';
import AisleNameInput from './AisleNameInput';
import AislePortInput from './AislePortInput';
import Button from 'react-bootstrap/Button';
import {connect} from 'react-redux';
import {addRackToAisle, changeAisleData, deleteAisle} from '../../actions/actions';
import {
    AISLE_CONNECTION_SIDE,
    AISLE_CONNECTION_SIDE_TYPES,
    AISLE_NAME,
    AISLE_PORT,
    pushState
} from './AisleStateManipulations';
import FormControl from 'react-bootstrap/FormControl';
import ButtonWithOverlayTrigger from './ButtonWithOverlayTrigger';
import remove from '../Controllers/remove.svg';

class AisleCardHeader extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            ...props
        };
        this.aisleValueChange = this.aisleValueChange.bind(this);
        this.changeConnectionSide = this.changeConnectionSide.bind(this);
    }

    aisleValueChange(e, fieldName) {
        const newState = {};
        newState[fieldName] = e.target.value.toUpperCase();
        this.setState(newState);
    }

    changeConnectionSide(e) {
        const newState = {};
        newState[AISLE_CONNECTION_SIDE] = e.target.value;
        this.setState(newState, () => pushState(this.state, this.props));
    }

    render() {
        return (
            <div className='aisle-card-header-content'>
                <div className='aisle-header-div'>
                    <WorkSpacePicker controllerId={this.props.controllerId}
                        aisleIndex={this.props.aisleIndex}/>
                </div>
                <div className='aisle-header-sm'>
                    <AisleNameInput value={this.state.name}
                        onBlur={(e) => pushState(this.state, this.props)}
                        onChange={(e) => this.aisleValueChange(e, AISLE_NAME)}
                        aisleGenerationSetting={this.props.aisleGenerationSetting}/>
                </div>
                <div className='aisle-header-sm'>
                    <AislePortInput max={this.props.maxPortsAllowed} value={this.state.port}
                        onBlur={(e) => pushState(this.state, this.props)}
                        onChange={(e) => this.aisleValueChange(e, AISLE_PORT)}/>
                </div>
                <div className='aisle-header-sm'>
                    <FormControl as="select"
                        size="sm"
                        className="shadow-none"
                        value={this.props.connectionSide}
                        onChange={this.changeConnectionSide}>
                        <option value={undefined} key="not_selected">Not Selected</option>
                        <option value={AISLE_CONNECTION_SIDE_TYPES.LEFT}>Left</option>
                        <option value={AISLE_CONNECTION_SIDE_TYPES.RIGHT}>Right</option>
                    </FormControl>
                </div>
                <Button className='aisle-header-add-rack' disabled={this.props.totalRacks >= 6}
                    onClick={(e) => this.props.addRack()}>Add Rack</Button>
                <div className='aisle-header-delete'>
                    <ButtonWithOverlayTrigger tooltipPrompt='Remove Aisle'
                        onClick={this.props.onDelete} additionalClasses='hide-in-mobile image-buttons'>
                        <img src={remove} alt={'Delete Aisle'}/>
                    </ButtonWithOverlayTrigger>
                </div>
            </div>
        );
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            ...nextProps
        });
    }
}

function mapStateToProps(state, ownProps) {
    const currentAisle = state.siteConfig.controllers[ownProps.controllerId].aisles[ownProps.aisleIndex];
    return {
        maxPortsAllowed: state.maxPortsAllowed,
        name: currentAisle.name,
        port: currentAisle.port,
        connectionSide: currentAisle.connectionSide,
        aisleType: currentAisle.aisleType ? currentAisle.aisleType.s3Key : undefined,
        racks: currentAisle.racks,
        totalRacks: currentAisle.racks.length,
        aisleGenerationSetting: state.aisleGenerationSetting
    };
}

function mapDispatchToProps(dispatch, ownProps) {
    return {
        onDelete: () => dispatch(deleteAisle(ownProps.controllerId, ownProps.aisleIndex)),
        onValueChange: (data) => dispatch(changeAisleData(ownProps.controllerId, ownProps.aisleIndex, data)),
        addRack: () => dispatch(addRackToAisle(ownProps.controllerId, ownProps.aisleIndex))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(AisleCardHeader);
