import React from 'react';
import CreatableSelect from 'react-select/creatable';
import { components } from 'react-select';
import {connect} from 'react-redux';
import {changeAisleWorkspace, createWorkSpace, confirmDeleteWorkSpace} from '../../actions/actions';
import Button from 'react-bootstrap/Button';
import {ReactComponent as RemoveWorkSpaceIcon} from '../Controllers/x.svg';
import './WorkSpacePicker.css';

class WorkSpacePicker extends React.Component {
    Option = (props) => {
        console.log(props);
        return (
            <div className={this.props.className}>
                <components.Option {...props} />
                {
                    props.data.__isNew__ ? ''
                        : (<Button className="delete-ws image-buttons no-border btn-outline-light"
                            onClick={() => this.props.deleteWorkspace(props.label, props.value)}>
                            <RemoveWorkSpaceIcon/>
                        </Button>)
                }
            </div>
        );
    };
    render() {
        const invalidStyle = {};
        if (this.props.isFormValidated) {
            invalidStyle.borderColor = !this.props.selected ? 'red' : 'green';
        }
        const customStyles = {
            control: (base) => ({
                ...base,
                ...invalidStyle
            }),
            menuPortal: (base) => ({ ...base, zIndex: 3})
        };
        return <CreatableSelect
            isClearable
            maxMenuHeight='31'
            menuPortalTarget={document.body}
            styles={customStyles}
            components={{Option: this.Option}}
            onCreateOption={this.props.createNewWorkspace}
            value={this.props.selected}
            onChange={this.props.changeWorkspace}
            noOptionsMessage={() => 'No Options. Create an option by entering a name and clicking Enter'}
            options={this.props.workspaces}/>;
    }
}

function mapStateToProps(state, ownProps) {
    return {
        selected: state.siteConfig.controllers[ownProps.controllerId].aisles[ownProps.aisleIndex].workspace,
        workspaces: state.siteConfig.workspaces,
        isFormValidated: state.validated
    };
}

function mapDispatchToProps(dispatch, ownProps) {
    return {
        createNewWorkspace: (name) => dispatch(createWorkSpace(name)),
        deleteWorkspace: (name, value) => dispatch(confirmDeleteWorkSpace(name, value)),
        changeWorkspace: (option) => dispatch(changeAisleWorkspace(ownProps.controllerId, ownProps.aisleIndex, option))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkSpacePicker);
