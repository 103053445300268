import React from 'react';
import {connect} from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';

class LoadingModal extends React.Component {
    render() {
        return <Modal show={this.props.show} size="sm" animation={false} centered>
            <Modal.Body>
                <Spinner animation="border" as="span"/>
                <h4 className="d-inline pl-2">{this.props.message}</h4>
            </Modal.Body>
        </Modal>;
    }
}

function mapStateToProps(state) {
    const {show, message} = state.modalStatus.loadingModal;
    return {show, message};
}

export default connect(mapStateToProps, null)(LoadingModal);
