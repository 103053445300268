import * as sitePlanningApi from '../apis/site-planning-api';
import SiteConfigV2Generator from './siteConfigGenerators/SiteConfigV2Generator';

let siteConfigGenerator = null;

export default async(state) => {
    const {siteConfig: siteConfigState, aisleGenerationSetting} = state;

    if (siteConfigGenerator == null) {
        siteConfigGenerator = new SiteConfigV2Generator(sitePlanningApi);
    }

    siteConfigGenerator.setAisleConfigGenerator(aisleGenerationSetting.aisleConfigGeneratorName);

    return await siteConfigGenerator.generateSiteConfig(siteConfigState);
};
