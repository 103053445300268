import React, {Component} from 'react';
import {Accordion, Card} from 'react-bootstrap';
import {ContextAwareToggle} from './ContextAwareAccordionToggle';
import AisleCardHeader from '../Aisle/AisleCardHeader';
import BinInputTable from './BinInputTable';

export class AisleCard extends Component {
    render() {
        return <Card key={this.props.eventKey} className={this.props.invalid ? 'invalid-aisle' : ''}>
            <Card.Header className='aisle-card-header'>
                <>
                    <ContextAwareToggle eventKey={this.props.eventKey}>
                        <AisleCardHeader
                            controllerId={this.props.controllerId}
                            aisleIndex={this.props.aisleIndex}
                        />
                    </ContextAwareToggle>
                </>
            </Card.Header>
            <Accordion.Collapse eventKey={this.props.eventKey} mountOnEnter>
                <div className="bin-input-wrapper">
                    {this.getRacks()}
                </div>
            </Accordion.Collapse>
        </Card>;
    }

    getRacks() {
        const rackTables = [];
        for (let i = 0; i < this.props.aisle.racks.length; i++) {
            rackTables.push((
                <BinInputTable key={i} controllerId={this.props.controllerId} aisleIndex={this.props.aisleIndex}
                    rackIndex={i}/>
            ));
        }
        return rackTables;
    }
}
