import React from 'react';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import {saveAs} from 'file-saver';
import {connect} from 'react-redux';
import * as XLSX from 'xlsx';
import {downloadConfig} from "../../actions/siteConfigActions";

class ExportToFileButton extends React.Component {
    async export() {
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';
        const headers = [["Aisle", "Bin", "BinType", "StartingPixelLeft", "EndingPixelLeft", "StartingPixelRight", "EndingPixelRight"],];
        const wscols = [{wch:6}, {wch:7}, {wch:10}, {wch:20}, {wch:20}, {wch:20}, {wch:20}];


        await this.props.downloadConfig();

        if(this.props.config.length) {
            const ws = XLSX.utils.json_to_sheet(this.props.config, {skipHeader: 0});
            XLSX.utils.sheet_add_aoa(ws, headers);
            ws['!cols'] = wscols;

            const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const data = new Blob([excelBuffer], {type: fileType});
            saveAs(data, this.props.stationName + "-Config" + fileExtension);
        }
    }

    render() {
        return <OverlayTrigger
            placement="auto"
            overlay={
                <Tooltip id={'tooltip-bottom'}>
                    Save aisle config for station <strong>{this.props.stationName}</strong> to local file system
                </Tooltip>
            }
        >
            <Button className="tall-button no-border shadow-none"
                onClick={this.export.bind(this)}>Export Config</Button>
        </OverlayTrigger>;
    }
}

function mapDispatchToProps(dispatch) {
    return {
        downloadConfig: () => dispatch(downloadConfig()),
    };
}

function mapStateToProps(state) {
    return {
        config: state.config,
        stationName: state.siteConfig.stationName
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ExportToFileButton);
