import AbstractAisleGenerator from './AbstractAisleGenerator';

class AisleBasedGenerator extends AbstractAisleGenerator {
    static getName() {
        return 'AisleBasedGenerator';
    }

    generateLabelReplacementMap(parameters, aisleName) {
        const replacementMap = {};

        if (aisleName.indexOf('-') !== -1) {
            const aisleNameWithoutDash = aisleName.split('-').join('');
            replacementMap['{aisleWithDash}'] = aisleName;
            replacementMap['{aisleWithoutDash}'] = aisleNameWithoutDash;
        } else {
            replacementMap['{aisleWithoutDash}'] = aisleName;
        }

        return replacementMap;
    }

    generateLabel(labelFormat, replacementMap) {
        let label = labelFormat;
        for (const [placeholder, replacement] of Object.entries(replacementMap)) {
            label = label.replace(placeholder, replacement);
        }
        return label;
    }

    generateAisle(macAddress, aisleParameters, aisleTemplate) {
        const {name: aisleName, port} = aisleParameters;
        const entities = [];

        const labelReplacementMap = this.generateLabelReplacementMap(aisleTemplate, aisleName);

        for (const entityTemplate of aisleTemplate.entities) {
            entities.push({
                label: this.generateLabel(entityTemplate.labelFormat, labelReplacementMap),
                segments: entityTemplate.segments
            });
        }

        return {
            label: aisleName,
            macAddress,
            port: parseInt(port, 10),
            entities
        };
    }
}

export default AisleBasedGenerator;
