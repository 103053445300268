import React from 'react';
import logo from '../../logo.svg';
import './Header.css';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import {Greetings} from 'aws-amplify-react';
import {connect} from 'react-redux';
import { getAppConfig } from '../../configs/app-config';

class Header extends React.Component {
    static getRegion() {
        const appConfig = getAppConfig();
        switch (appConfig.region) {
            case 'us-west-2':
                return 'NA';
            case 'eu-west-1':
                return 'EU';
            case 'ap-northeast-1':
                return 'FE';
            default:
                return 'Unknown Region';
        }
    }

    static getStage() {
        const appConfig = getAppConfig();
        return appConfig.stage;
    }

    render() {
        return <Navbar className="navbar-expand-md navbar-dark bg-squid-ink">
            <div className="navbar-collapse collapse w-50 order-1 order-md-0 dual-collapse2">
                <Navbar.Brand href="#home">
                    <img
                        src={logo}
                        width="100"
                        height="33"
                        className="d-inline-block align-top" alt="Amazon logo"/>
                </Navbar.Brand>
            </div>
            <div className="hide-in-mobile mx-auto w-100 order-0">
                <h4 className="navbar-header">{this.props.titleText}</h4>
            </div>
            <Navbar.Collapse className="w-75 order-3 dual-collapse2">
                <Nav className="ml-auto mr-3">
                </Nav>
                <Greetings />
            </Navbar.Collapse>
        </Navbar>;
    }
}

function mapStateToProps(state) {
    const siteId = state.siteConfig && state.siteConfig.stationName !== null
        ? state.siteConfig.stationName
        : '';
    let titleText = `SITE PLANNING - ${Header.getRegion()}/${Header.getStage()} - ${siteId}`;

    if (state.saved === false) {
        titleText += ' - Unsaved';
    }

    return {
        titleText
    };
}

export default connect(mapStateToProps, null)(Header);
