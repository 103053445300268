import {SignIn} from 'aws-amplify-react';

// TODO: this class should be moved into a separate package for reusability
class SignInSkipVerifyContact extends SignIn {
    constructor(props) {
        super(props);
        this.checkContact = this.checkContact.bind(this);
    }

    checkContact(user) {
        this.changeState('signedIn', user);
    }

    showComponent(theme) {
        delete this.props.hide[this.props.hide.indexOf(SignIn)];

        return super.showComponent(theme);
    }
}

export default SignInSkipVerifyContact;
