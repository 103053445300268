import {API} from 'aws-amplify';
import {API_NAMES} from '../constants';
import {getJwtToken} from "./auth";

// API call to set the site id of controller to NOT_IN_USE (soft delete)
async function deleteThingResources(controllerName) {
    const jwtToken = await getJwtToken();
    try {
        const path = `/v1/device/ledController/${controllerName}/softDelete`;
        return await API.post(API_NAMES.siteManagementApi, path, {
            headers: { Authorization: `Bearer ${jwtToken}`},
        });
    } catch (e) {
        console.error(`Error deleting device for ${controllerName}. ` + e.toString());
    }
}

export {deleteThingResources};
