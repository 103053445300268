import {CHANGE_COLOR_ORDER} from '../../actions/actionTypes';

export default (state = 'RGB', action) => {
    switch (action.type) {
        case CHANGE_COLOR_ORDER:
            return action.colorOrder;
        default:
            return state;
    }
};
