import {
    IMPORT_SITE_CONFIG_FROM_FILE,
    RESET_SITE_CONFIG,
    EXPAND_LED_CONTROLLER,
    CONFIG_SAVED, SAVE_FIRST_INVALID_AISLE, IMPORT_CONFIG_FROM_FILE
} from './actionTypes';
import * as SitePlanningApi from '../apis/site-planning-api';
import convertSiteConfigToInternalState from '../transformations/convertSiteConfigToInternalState';
import convertInternalStateToSiteConfig from '../transformations/convertInternalStateToSiteConfig';
import {showPrompt, formValidated, openLoadingModal, closeLoadingModal} from './notificationActions';
import validateState from '../validations/validations';
import { PROMPT_LEVELS, CONTROLLER_PREFIX, DASH } from '../constants';
import AppDataStore, {APP_DATA_KEYS} from "../components/app-data";
import {deleteThingResources} from "../apis/soft-delete-api";

const NOT_FOUND_ERROR_MESSAGE = 'Request failed with status code 404';

function importSiteConfigHelper(dispatch, getState, newSiteConfig) {
    // check new site config id vs current siteId
    if (newSiteConfig == null) {
        return;
    }
    const newSiteId = newSiteConfig.stationName;
    const siteId = getState().siteConfig.stationName;
    if (newSiteId == null || newSiteId === '' || newSiteId === siteId) {
        newSiteConfig.stationName = siteId;
        dispatch({
            type: IMPORT_SITE_CONFIG_FROM_FILE,
            siteConfig: newSiteConfig
        });
    } else {
        throw new Error(`Config to load is for a different site (${newSiteId})`);
    }
}

function showErrorPrompt(dispatch, header, error) {
    const errorMessage = error == null
        ? ''
        : error.message;
    dispatch(showPrompt({
        level: PROMPT_LEVELS.ERROR,
        header,
        message: errorMessage
    }));
}

export function importFromFile(file) {
    return (dispatch, getState) => {
        try {
            const siteConfigInput = JSON.parse(file);
            const newSiteConfig = convertSiteConfigToInternalState(siteConfigInput);
            importSiteConfigHelper(dispatch, getState, newSiteConfig);
        } catch (error) {
            showErrorPrompt(dispatch, 'Error importing site config from file', error);
        }
    };
}

export function loadFromCloud() {
    return async(dispatch, getState) => {
        const siteId = getState().siteConfig.stationName;
        // request info
        try {
            // show modal
            dispatch(openLoadingModal('Loading...'));
            if (siteId == null || siteId === '') {
                dispatch({
                    type: RESET_SITE_CONFIG
                });
            } else {
                const siteConfigInServerFormat = await SitePlanningApi.loadSiteConfig(siteId);
                console.log(siteConfigInServerFormat);
                const newSiteConfig = convertSiteConfigToInternalState(siteConfigInServerFormat);
                importSiteConfigHelper(dispatch, getState, newSiteConfig);
            }
            // exit modal
            dispatch(showPrompt({
                level: PROMPT_LEVELS.INFO,
                header: `Site config for ${siteId} loaded from cloud`
            }));
        } catch (error) {
            if (error && error.message === NOT_FOUND_ERROR_MESSAGE) {
                // Pre-populate for new site.
                try {
                    dispatch(openLoadingModal('No site config found, prepopulating from site resources...'));
                    const prepopulatedSiteConfig = await SitePlanningApi.prepopulateSiteConfig(siteId);
                    const newSiteConfig = convertSiteConfigToInternalState(prepopulatedSiteConfig);
                    importSiteConfigHelper(dispatch, getState, newSiteConfig);
                    // Show help message telling what's happening
                    dispatch(showPrompt({
                        level: PROMPT_LEVELS.INFO,
                        header: `${siteId} config successfully pre-populated`,
                        message: `${siteId} is a new site and it's pre-populated from site resources in our system. Please add necessary configs, modify and confirm the site config before saving and deploying.`
                    }));
                } catch (errorInner) {
                    if (errorInner && errorInner.message === NOT_FOUND_ERROR_MESSAGE) {
                        dispatch({
                            type: RESET_SITE_CONFIG
                        });
                        dispatch(showPrompt({
                            level: PROMPT_LEVELS.WARN,
                            header: `Create config for new site ${siteId}`,
                            message: `Site config or site resources for ${siteId} do not exist. Setting up as a new site`
                        }));
                    }
                }
            } else {
                showErrorPrompt(dispatch, 'Error loading site config from cloud', error);
            }
        } finally {
            dispatch(closeLoadingModal());
            dispatch(formValidated(false));
        }
    };
}

export function uploadConfig(configFile) {
    return async(dispatch, getState) => {
        const state = getState();
        try {
            dispatch(openLoadingModal('Saving...'));
            const saveResult = await SitePlanningApi.uploadPixelConfig(state.siteConfig.stationName, configFile);
            console.log("saveResult:", saveResult);
            //exit modal

            dispatch(showPrompt({
                level: PROMPT_LEVELS.INFO,
                header: 'Config saved successfully'
            }));
        } catch (error) {
            showErrorPrompt(dispatch, 'Failed to save config to cloud', error);
        } finally {
            dispatch(closeLoadingModal());
        }
    };
}

export function downloadConfig() {
    return async(dispatch, getState) => {
        const siteId = getState().siteConfig.stationName;
        try {
            dispatch(openLoadingModal('Loading...'));
            if (siteId == null || siteId === '') {
                dispatch({
                    type: RESET_SITE_CONFIG
                });
            } else {
                const configFromServer = await SitePlanningApi.loadPixelConfig(siteId);
                console.log("configFromServer:", configFromServer);
                dispatch({
                    type: IMPORT_CONFIG_FROM_FILE,
                    config: configFromServer
                });
            }
            // exit modal
            dispatch(showPrompt({
                level: PROMPT_LEVELS.INFO,
                header: `Config sheet for ${siteId} loaded from cloud`
            }));
        } catch (error) {
            if (error && error.message === NOT_FOUND_ERROR_MESSAGE) {
                // Create new sheet
                dispatch(openLoadingModal('No config found, creating template for station...'));
                try{
                    const config = await SitePlanningApi.createPixelConfig(siteId);
                    console.log("Create config")

                    var configList = [];
                    config.forEach( (entry) => {
                        configList.push([entry.aisle, entry.bin, entry.binType, entry.startingPixelLeft,
                            entry.endingPixelLeft, entry.startingPixelRight, entry.endingPixelRight]);
                    })
                    dispatch({
                        type: IMPORT_CONFIG_FROM_FILE,
                        config: configList
                    });

                    dispatch(showPrompt({
                        level: PROMPT_LEVELS.INFO,
                        header: `${siteId} config successfully created`,
                        message: `${siteId} is a new site and it's pre-populated from site resources in our system.`
                    }));
                } catch {
                    dispatch(showPrompt({
                        level: PROMPT_LEVELS.ERROR,
                        header: `${siteId} config template cannot be created`,
                        message: `${siteId} is a new site and it's resources are not pre-populated in our system.`
                    }));
                }
            } else {
                showErrorPrompt(dispatch, 'Error loading config template from cloud', error);
            }
        } finally {
            dispatch(closeLoadingModal());
        }
    };
}

export function saveToCloud() {
    return async(dispatch, getState) => {
        const state = getState();
        try {
            const stateValidity = validateState(state);
            dispatch(formValidated(true));

            if (!stateValidity.valid) {
                if (typeof stateValidity.firstInvalid !== 'undefined') {
                    dispatchEventsToShowInvalid(dispatch, stateValidity);
                }
                throw new Error('Config has errors. Fix and try again');
            }
            // send data to server and get response
            //show modal
            dispatch(openLoadingModal('Saving...'));
            const siteConfigInServerFormat = await convertInternalStateToSiteConfig(state);
            console.log(siteConfigInServerFormat);
            const saveResult = await SitePlanningApi.saveSiteConfig(state.siteConfig.stationName, siteConfigInServerFormat);
            console.log(saveResult);
            //exit modal
            // display response
            dispatch(showPrompt({
                level: PROMPT_LEVELS.INFO,
                header: 'Site config saved successfully'
            }));
            dispatch(formValidated(false));
            dispatch({type: CONFIG_SAVED});

            /**
             * check if any controller was deleted
             * if so, perform soft delete on the controllers in aws account
             */
            const siteName = state.siteConfig.stationName;
            const toBeDeleteControllers = AppDataStore.get(APP_DATA_KEYS.TO_BE_DELETE_CONTROLLERS);
            if (toBeDeleteControllers.length === 0){
                console.log("no controllers to be deleted");
            } else {
                await controllerSoftDelete(toBeDeleteControllers, siteName);
            }

        } catch (error) {
            showErrorPrompt(dispatch, 'Failed to save site config to cloud', error);
        } finally {
            dispatch(closeLoadingModal());
        }
    };
}

async function controllerSoftDelete(controllerList, siteId) {

    // loop through list of deleted controllers and set them NOT_IN_USE individually
    for (const controller of controllerList) {
        /**
         * led controllers are named in aws acc in a specific format
         * controllerNameString = 'ledController-<mac address>-<siteId>'
         * e.g: 'ledController-e0:b5:e8:e3:27:81-DSE5'
         */
        if (controller === "") {
            continue;
        }
        const controllerNameString = CONTROLLER_PREFIX + DASH + String(controller) + DASH + String(siteId);
        console.log('deleting controller:', controllerNameString);
        await deleteThingResources(controllerNameString);
    }

    AppDataStore.save(APP_DATA_KEYS.TO_BE_DELETE_CONTROLLERS, []);
}

function dispatchEventsToShowInvalid(dispatch, stateValidity) {
    dispatch({
        type: EXPAND_LED_CONTROLLER,
        controllerId: stateValidity.firstInvalid
    });
    if (typeof stateValidity.firstInvalidAisle !== 'undefined') {
        dispatch({
            type: SAVE_FIRST_INVALID_AISLE,
            controllerId: stateValidity.firstInvalid,
            aisleIndex: stateValidity.firstInvalidAisle
        });
    }
}

export function deploy() {
    return async(dispatch, getState) => {
        const state = getState();
        const stateValidity = validateState(state);
        dispatch(formValidated(true));

        if (!stateValidity.valid) {
            if (typeof stateValidity.firstInvalid !== 'undefined') {
                dispatchEventsToShowInvalid(dispatch, stateValidity);
            }
            return;
        }
        // send data to server and get response
        try {
            //show modal
            dispatch(openLoadingModal('Deploying...'));

            const siteConfigInServerFormat = await convertInternalStateToSiteConfig(state);
            console.log("server format",siteConfigInServerFormat);
            const deployResult = await SitePlanningApi.deploySiteConfig(state.siteConfig.stationName, siteConfigInServerFormat);
            console.log("deployResult", deployResult);
            // display response
            dispatch(showPrompt({
                level: PROMPT_LEVELS.INFO,
                header: 'Site config saved and deployed successfully'
            }));
            dispatch(formValidated(false));
            dispatch({type: CONFIG_SAVED});
        } catch (error) {
            showErrorPrompt(dispatch, 'Failed to save and deploy site config', error);
        } finally {
            dispatch(closeLoadingModal());
        }
    };
}
