import React, {Component} from 'react';
import {OverlayTrigger} from 'react-bootstrap';
import Tooltip from 'react-bootstrap/Tooltip';

export class AisleHeaderWithToolTip extends Component {
    render() {
        return (
            <div className='aisle-card-header-content aisle-card-header-tooltip-row'>
                <div className='aisle-header-div'>
                    {this.getOverlayTrigger(
                        <span> To create a new workspace enter the name in the select and press enter </span>
                        , 'WorkSpace', 'right')}
                </div>
                <div className='aisle-header-sm'>
                    {this.getOverlayTrigger('Chute Name', 'Name')}
                </div>
                <div className='aisle-header-sm'>
                    {this.getOverlayTrigger('Port No. the aisle is connected to', 'Port')}
                </div>
                <div className='aisle-header-sm'>
                    {this.getOverlayTrigger(
                        <span> Left: The connection in the rack are made from left to right <br/>
                        Right: The connections in the rack are made from right to left</span>,
                        'Connection Side')}
                </div>
                <div className='aisle-header-add-rack'>
                    {/*Intentionally empty because Add Rack doesn't need a Header*/}
                </div>
                <div className='aisle-header-delete'>
                    {this.getOverlayTrigger('delete rack', 'Actions', 'left', false)}
                </div>
            </div>
        );
    }

    getOverlayTrigger(prompt, label, placement = 'left', required = true) {
        return <OverlayTrigger
            placement={placement ? placement : 'left'}
            overlay={
                <Tooltip>
                    {prompt}
                </Tooltip>
            }>
            <span>{label}{required ? <span className="required-field">*</span> : undefined}</span>
        </OverlayTrigger>;
    }
}
