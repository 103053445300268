import {CHANGE_STATION} from '../../actions/actionTypes';

export default (state = '', action) => {
    switch (action.type) {
        case CHANGE_STATION:
            return action.siteId;
        default:
            return state;
    }
};
