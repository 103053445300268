import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import {connect} from 'react-redux';
import {closeDuplicateControllerModal, duplicateController} from '../../actions/actions';
import {getAisleNameAttributes} from '../../configs/app-config';
import {MAC_ADDRESS_PATTERN} from '../../constants';
import { isValidAisleName, isValidMacAddress } from '../../validations/validations';

class DuplicateControllerModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = DuplicateControllerModal.getInitialState();
    }

    static getInitialState() {
        return {
            startAisle: '',
            macAddress: '',
            validated: false
        };
    }

    isStateValid() {
        const {startAisle, macAddress} = this.state;
        if (startAisle == null || startAisle === '' || !isValidAisleName(startAisle, this.props.aisleGenerationSetting)) {
            return false;
        }
        if (macAddress != null && macAddress !== '' && !isValidMacAddress(macAddress)) {
            return false;
        }
        return true;
    }

    handleClose() {
        this.setState(DuplicateControllerModal.getInitialState());
        this.props.close();
    }

    handleConfirm() {
        this.setState({validated: true});
        if (this.isStateValid()) {
            this.props.duplicate(
                this.props.sourceControllerId,
                this.state.startAisle,
                this.state.macAddress
            );
            this.handleClose();
        }
    }

    handleStartAisleChange(event) {
        const startAisle = event.target.value.toUpperCase();
        this.setState({startAisle});
    }

    handleMacAddressChange(event) {
        const macAddress = event.target.value;
        this.setState({macAddress});
    }

    render() {
        const {aisleNamePlaceholder, aisleNamePattern} = getAisleNameAttributes(this.props.aisleGenerationSetting);
        return <Modal show={this.props.show} onHide={this.handleClose.bind(this)}>
            <Form noValidate
                validated={this.state.validated}
                onSubmit={(event) => {
                    event.preventDefault();
                    this.handleConfirm();
                }}
            >
                <Modal.Header closeButton>{this.props.title}</Modal.Header>
                <Modal.Body>
                    <InputGroup className="mt-2 mb-2">
                        <InputGroup.Prepend>
                            <InputGroup.Text>Start Aisle &nbsp; <span className="required-field">*</span></InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl required
                            placeholder={aisleNamePlaceholder}
                            pattern={aisleNamePattern}
                            value={this.state.startAisle}
                            onChange={this.handleStartAisleChange.bind(this)}/>
                        <FormControl.Feedback type="invalid">
                            {`Aisle name must have a valid format. Example: ${aisleNamePlaceholder}`}
                        </FormControl.Feedback>
                    </InputGroup>
                    <InputGroup className="mt-2 mb-2">
                        <InputGroup.Prepend>
                            <InputGroup.Text>MAC Address</InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl placeholder="ff:ff:ff:ff:ff:ff"
                            pattern={MAC_ADDRESS_PATTERN}
                            value={this.state.macAddress}
                            onChange={this.handleMacAddressChange.bind(this)}/>
                        <FormControl.Feedback type="invalid">
                            MAC address must have a valid format. Example: aa:aa:aa:aa:aa:aa
                        </FormControl.Feedback>
                    </InputGroup>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={this.handleClose.bind(this)}>Cancel</Button>
                    <Button variant="success" type="submit">Confirm</Button>
                </Modal.Footer>
            </Form>
        </Modal>;
    }
}

function mapStateToProps(state) {
    const {show, sourceControllerId} = state.modalStatus.duplicateController;
    const title = sourceControllerId == null ? 'Invalid source controller' : `Duplicate from controller ${sourceControllerId + 1}`;
    return {
        show, sourceControllerId, title,
        aisleGenerationSetting: state.aisleGenerationSetting
    };
}

function mapDispatchToProps(dispatch) {
    return {
        close: () => dispatch(closeDuplicateControllerModal()),
        duplicate: (sourceControllerId, startAisle, macAddress) => dispatch(duplicateController(sourceControllerId, startAisle, macAddress))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(DuplicateControllerModal);
