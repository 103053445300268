import React, {Component} from 'react';
import {Col, Container, Form, FormControl, Row} from 'react-bootstrap';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import {connect} from 'react-redux';
import AisleTable from '../Aisle/AisleTable';
import {addSingleAisle, changeMacAddress, toggleAddAislesModal} from '../../actions/actions';
import {MAC_ADDRESS_PATTERN} from '../../constants';
import ManualEntryBinTypeAisle from './ManualEntryBinTypeAisle';

export class ControllerBody extends Component {
    render() {
        return <Container className="controller-body">
            <Row>
                <Col sm={12} xs={12} md={5} xl={5} className="macaddress-input">
                    <InputGroup>
                        <InputGroup.Prepend>
                            <InputGroup.Text>
                                MAC &nbsp;
                                <span className="hide-in-mobile">Address&nbsp;</span>
                                <span className="required-field">*</span></InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                            required
                            placeholder="e0:b6:f5:e0:27:84"
                            aria-label="Mac-Address"
                            className="shadow-none lowercase-input"
                            pattern={MAC_ADDRESS_PATTERN}
                            value={this.props.macAddress}
                            onChange={(e) => {
                                this.props.changeMacAddress(e.target.value);
                            }}
                        />
                        <Form.Control.Feedback type="invalid">Invalid MAC Address</Form.Control.Feedback>
                    </InputGroup>
                </Col>
                <Col sm={3} xs={5} md={1} xl={2} className="add-row-div">
                    <Button className="add-row-btn no-border shadow-none ml-3" onClick={this.props.addAisle}>
                        <span className="hide-in-mobile">Add</span>
                        <span className="hide-in-desktop">+</span> One Row
                    </Button>
                </Col>
                <Col sm={5} xs={7} md={3} xl={2} className="add-row-div">
                    <Button className="add-row-btn no-border shadow-none" onClick={this.props.addMultipleAisles}>
                        <span>Add Multiple Rows</span>
                    </Button>
                </Col>
            </Row>
            <Row>
                <div className="aisle-form col-md-12">
                    { this.props.aisleGenerationSetting.shouldEnterBinLabel
                        ? <ManualEntryBinTypeAisle controllerId={this.props.controllerId}/>
                        : <AisleTable controllerId={this.props.controllerId}/>
                    }
                </div>
            </Row>
        </Container>;
    }
}

function mapStateToProps(state, ownProps) {
    const currentController = state.siteConfig.controllers[ownProps.controllerId];
    const totalAisles = currentController.aisles.length;
    return {
        aisleGenerationSetting: state.aisleGenerationSetting,
        macAddress: currentController.macAddress,
        totalAisles: totalAisles
    };
}

function mapDispatchToProps(dispatch, ownProps) {
    return {
        addAisle: () => dispatch(addSingleAisle(ownProps.controllerId)),
        addMultipleAisles: () => dispatch(toggleAddAislesModal(true)),
        changeMacAddress: (macAddress) => dispatch(changeMacAddress(ownProps.controllerId, macAddress.toLowerCase()))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ControllerBody);
